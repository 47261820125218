@import "../utils/assets/typography";
@import "../utils/assets/variables";
@import "../utils/assets/scaffoldings";

.offline-page {
    overflow: hidden;
    padding-bottom: 7.5vh;
    background: darken($color: #ffffff, $amount: 8%);

    .image {
        max-width: 45vw;
        max-height: 35vh;
        margin-bottom: 15px;
    }

    .title {
        font-family: "Hammersmith One", Verdana, Arial, Helvetica, sans-serif;
        font-size: 36px;
        color: $primary;
        margin-bottom: 10px;

        @media screen and (min-width: 768) {
            font-size: 42px;
        }
    }

    .content {
        font-size: 20px;
        font-weight: $font-weight-normal;
        color: #000000;
        margin-bottom: 0;
        max-width: 90vw;

        @media screen and (min-width: 768) {
            font-size: 24px;
            max-width: 510px;
        }
    }

    .logo {
        bottom: 20px;
        filter: grayscale(100%) brightness(1.70);
        height: 42px;

        @media screen and (min-height: 480px) {
            height: auto;
        }
    }
}