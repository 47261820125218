@import "../../utils/assets/mixins";

.page-title {
  &.createProductFix {
    @include media-sm {
      font-size: 31px;
      line-height: 49px;
    }
  }
}

.page-area {
  overflow: hidden;
  padding: 3rem;

  &.createProductFix {
    @include media-sm {
      margin-top: 24px;
      padding: 23px 20px 34px;

      &.firstStepFix {
        padding: 23px 13px 34px;
      }
    }

    .react-datepicker {
      .react-datepicker__month-container {
        width: 100%;

        @include media-xs-min {
          width: 50%;

          .react-datepicker__month {
            .react-datepicker__week {
              .react-datepicker__day {
                &.react-datepicker__day--selected,
                &.react-datepicker__day--in-selecting-range,
                &.react-datepicker__day--in-range,
                &.react-datepicker__day--keyboard-selected {
                  background-color: #e75b2b;
                }
              }
            }
          }
        }
      }
    }
  }

  .step-navbar {
    .step-link {
      position: relative;

      a {
        color: #fff;
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #e75b2b;

        &.disabled {
          cursor: default;
          pointer-events: none;
          background: transparent !important;
        }

        &.active {
          background: #e75a2b;
        }

        &.filled {
          background: rgb(128, 216, 140);
          background: linear-gradient(
            125deg,
            rgba(128, 216, 140, 1) 0%,
            rgba(109, 212, 152, 1) 35%,
            rgba(56, 202, 187, 1) 100%
          );
          border-color: transparent;
        }

        &[data-is-disabled="true"] {
          pointer-events: none;
          cursor: default;
          background: #ccc;
        }
      }

      &:after {
        content: "";
        width: 30px;
        height: 1px;
        background: #d8d8d8;
        position: absolute;
        right: 0;
        transform: translateX(calc(100% + 7px));

        @include media-sm {
          width: 10px;
        }
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }

  .question-cards {
    position: relative;

    @include media-sm {
      margin-top: 30px;
    }

    .question-cards-holder {
      position: relative;
      width: 100%;

      .swiper-slide {
        width: calc(80% - 30px);
      }
    }

    .question-card {
      width: 100%;
      height: 437px;
      background: #fff;
      box-shadow: 0 16px 42px rgba(#000, 0.09);
      padding: 20px 15px;
      position: relative;
      border-radius: 20px;

      label {
        width: 87%;
        font-size: 16px;
        font-weight: 700;
        color: #e75b2b;
      }

      textarea {
        width: 100%;
        min-height: 256px;
        border: 1px solid #e6e6e6;
        resize: vertical;
        border-radius: 4px;
        max-height: 320px;

        @include media-md {
          resize: none;
        }
      }
    }
  }

  .question-link {
    width: 100%;

    .swiper-pagination-bullet {
      width: 9px;
      height: 9px;
      background: #e3e3e3;
      border-radius: 50%;
      display: block;
      margin: 0 3.5px;
      transition: all 200ms;
      outline: none;
      opacity: 1;
      cursor: pointer;

      &.filled {
        background: #60d2a2;
      }

      &.swiper-pagination-bullet-active {
        width: 20px;
        border-radius: 4.55px;
        background: #e75b2b;
      }
    }
  }

  .step-title {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    color: #515151;
  }

  .step-desc {
    font-size: 13px;
    font-weight: 400;

    &.sub {
      font-size: 12px;
    }
  }

  .image-upload-area {
    border: 0.5px dashed #ffb399;
    border-radius: 5px;
    padding: 15px;

    .drop-file-overlay {
      top: 0;
      left: 0;
      background: rgba($color: #fff, $alpha: 0.7);
      border: 0.5px solid #ffb399;
      border-radius: 5px;
      color: #000;
      font-size: 17px;
      font-weight: 600;
    }

    .add-new {
      width: 95px;
      height: 95px;
      border: 0.5px solid #ffb399;
      background: #fff6f3;
      border-radius: 5px;
      position: relative;

      @include media-sm {
        width: 84px;
        height: 84px;
      }

      span {
        color: #e75b2b;
        font-size: 12px;
        font-weight: 600;
      }

      &:disabled {
        -webkit-filter: grayscale(100%);
        /* For Safari 6.0 to 9.0 */
        filter: grayscale(100%);
      }
    }

    .thumbsContainer {
      .thumb {
        width: 95px;
        height: 95px;
        border: 0.5px solid #ffb399;
        background: #fff6f3;
        border-radius: 5px;
        overflow: hidden;

        @include media-sm {
          width: 84px;
          height: 84px;
          margin: 0 11px 11px 0;

          &:nth-child(3n + 3) {
            margin-right: 0;
          }
        }

        .thumbInner {
          width: 100%;
          height: 100%;
        }
      }

      .close-btn {
        z-index: 3;
        top: 3px;
        right: 3px;
        width: 22px;
        height: 22px;

        .fas {
          font-size: 8px;
          line-height: 20px;
        }
      }
    }

    .info {
      font-size: 12px;
      font-weight: 400;
      color: #e75b2b;

      span {
        font-weight: 700;
      }
    }
  }

  .priceInfo {
    .price-and-currency {
      input {
        padding-right: 85px;
      }

      .currency-select {
        right: 5px;
        z-index: 3;

        select {
          padding: 10px 20px;
          border: none;
          opacity: 0.4;
        }
      }
    }

    .remove-button {
      color: #e13248;
      font-size: 11px;
      font-weight: 600;
    }
  }

  .price-info-nav-holder {
    .price-info-nav {
      max-width: calc(77% - 1rem);

      @include media-xs {
        max-width: calc(65% - 1rem);
      }

      @include media-sm-min {
        max-width: calc(77% - 1rem);
      }

      @include media-md-min {
        max-width: calc(79% - 1rem);
      }

      @include media-lg-min {
        max-width: calc(76% - 1rem);
      }

      .prices-holder {
        min-width: min-content;
        .price-info-nav-item {
          width: 24px;
          height: 24px;
          border: 1px solid #e75b2b;
          border-radius: 50%;
          color: #e75a2b;
          font-weight: 500;

          &.active {
            background: #e75b2b;
            color: #fff;
          }
        }
      }
    }

    .add-button {
      color: #e75b2b;
      font-size: 12px;
      font-weight: 600;
      background: none;
      border: none;
      width: 23%;

      @include media-xs {
        width: 35%;
      }

      @include media-sm-min {
        width: 23%;
      }

      @include media-md-min {
        width: 21%;
      }

      @include media-lg-min {
        width: 24%;
      }
    }
  }

  .time-info {
    .remove-button {
      color: #e13248;
      font-size: 11px;
      font-weight: 600;
    }
  }

  .DateRangePicker {
    display: flex;
    justify-content: space-between;

    @include media-sm {
      flex-direction: column;
    }

    .DateRangePicker__PaginationArrow:hover {
      background: transparent;
    }

    .DateRangePicker__Month {
      width: 214px;
      margin: 0;

      @include media-sm {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }

  .prices-holder {
    .show-more-button {
      .icon {
        transition: transform 0.3s ease-in-out;
      }

      &[data-state="show"] {
        .icon {
          transform: rotate(180deg);
        }
      }
    }
    .rest {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;

      &[data-hidden="false"] {
        max-height: 1000px;
      }

      .currency-info {
        .currency {
          font-size: 15px;
          font-weight: 500;
        }

        .desc {
          font-size: 13px;
        }
      }
    }

    .price-holder {
      header {
        padding: 15px;
        background: #d6d5d5;
        border-radius: 5px 5px 0 0;
        color: #000;
        font-size: 18px;
        font-weight: 700;
      }

      .prices {
        .single-price {
          padding: 15px;
          border: 1px solid #dedede;
          border-top: none;

          &:last-child {
            border-radius: 0 0 5px 5px;
          }
        }
      }
    }
  }

  .availability-modal {
    .content {
      width: 350px;
      max-width: 100vw;
    }
  }
}

.bl-modal-content {
  &.product-fix {
    overflow: auto;

    .title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
      color: #333;

      @include media-sm {
        font-size: 18px;
        letter-spacing: -0.4px;
      }
    }

    .content {
      overflow-y: auto;
      font-size: 13px;
      margin-bottom: 20px;

      p {
        &:last-child {
          margin: 0;
        }
      }
    }

    .close-button {
      padding: 14px 0;
      font-size: 17px;
      font-weight: bold;
      align-self: center;
    }
  }
}
