.AccountCompletion {
  .steps {
    .step {
      margin: 5px 0;

      @media (min-width: 768px) {
        margin: 0;
      }

      .icon {
        width: 24px;
        height: 24px;
        border: 1px solid rgba($color: #ffffff, $alpha: 0.75);
        background-color: transparent;
        margin-right: 5px;

        &[data-status="true"] {
          background-color: #ffffff;
        }
      }

      .name {
        font-size: 15px;
        font-weight: 500;
        color: #ffffff;
        line-height: 22.2px;
      }
    }

    .divider {
      width: 30px;
      height: 1px;
      background-color: #ffffff;
      display: block;
      margin: 0 7px;
    }
  }

  .page-area {
    margin-top: 50px;
    max-width: 530px;

    $page-area: &;

    .content-title {
      font-size: 22px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 30px;
    }

    .pages-holder {
      width: calc(100% * 3);
      transition: transform 300ms;

      @for $i from 1 through 3 {
        &[data-step="#{$i}"] {
          transform: translateX(
            calc(((-100% / 3) * #{$i - 1}) - (80px * #{$i - 1} + 40px))
          );

          .page:nth-child(#{$i}) {
            position: relative;
          }
        }
      }

      .page {
        flex-shrink: 0;
        width: calc(100% / 3);
        margin: 0 40px;
        position: absolute;

        @for $i from 1 through 3 {
          &:nth-child(#{$i}) {
            left: calc(100% / 3 * #{$i - 1} + 80px * #{$i - 1});
          }
        }
      }
    }

    .content-holder {
      .types {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
        .type {
          font-size: 14px;
          font-weight: 400;
          color: #1b1b1b;
          border: 1px solid #e6e6e6;
          border-radius: 4px;
          height: 50px;
          transition: all 200ms;

          &.selected {
            font-weight: 600;
            border-color: $primary;
          }

          &:hover:not(.selected) {
            box-shadow: 0px 0px 6px rgba($color: #000000, $alpha: 0.1);
          }
        }
      }

      .other-type-text {
        font-size: 12px;
        font-weight: 400;
        color: #000000;
        margin: 30px 0 25px;

        a {
          font-weight: 700;
          color: $primary;
          text-decoration: underline;
        }
      }

      .document-area {
        border: 1px solid #eaeaea;
        background-color: #f8f8f8;
        padding: 18px 25px;
        border-radius: 4px;
        margin-bottom: 15px;

        .status {
          width: 30px;
          height: 30px;
          position: absolute;
          top: 20px;
          left: 0;
          transform: translateX(-50%);
          background-color: #ffffff;
          border: 1.5px solid #eaeaea;
          border-radius: 50%;
          z-index: 9;

          .done-tick {
            opacity: 0;
            transition: opacity 200ms;
            border-radius: 50%;
            overflow: hidden;
          }

          .remove-icon {
            width: 28px;
            height: 28px;
            top: 0;
            left: 0;
            opacity: 0;
            background: $primary;
            transition: opacity 300ms;
            z-index: 8;

            svg {
              height: 18px;
            }

            &[data-force-show="true"] {
              box-shadow: 0 0 14px 5px rgba($color: #000000, $alpha: 0.1);
              opacity: 1;
              transition-delay: 1250ms;
            }
          }

          &[data-status="true"] {
            border: none;
            .done-tick {
              opacity: 1;
              animation: popAndShake 600ms ease 0ms 1;
            }
          }

          .document-remove-info {
            width: max-content;
            max-width: 220px;
            height: auto;
            top: 0;
            left: 0;
            padding: 12px 10px 12px 48px;
            transform: translate(-8px, -8px);
            background: rgb(231, 91, 43);
            background: linear-gradient(
              129deg,
              rgba(231, 91, 43, 1) 20%,
              rgba(254, 185, 132, 1) 180%
            );
            box-shadow: 0 0 12px 6px rgba($color: #000000, $alpha: 0.1);
            border-radius: 4px;
            z-index: 5;

            p {
              color: #ffffff;
              font-size: 14px;
              line-height: 18px;
              max-width: 160px;
            }

            svg {
              width: 16px;
              height: 16px;
            }

            &-enter {
              opacity: 0;
              z-index: 5;
            }

            &-enter-active {
              opacity: 1;
              transition: opacity 300ms;
              transition-delay: 1250ms;
            }

            &-exit {
              opacity: 1;
              z-index: 5;
            }

            &-exit-active {
              opacity: 0;
              transition: opacity 300ms;
            }
          }
        }

        .document-title {
          font-size: 15px;
          font-weight: 600;
          color: #000000;
          margin-bottom: 5px;
        }

        .document-desc {
          font-size: 12px;
          font-weight: 500;
          color: #aaaaaa;
          margin-bottom: 10px;
        }

        .dropzone {
          border: 1px dashed #aaaaaa;
          padding: 10px;
          background-color: #ffffff;
          cursor: pointer;

          &[data-is-active="false"] {
            pointer-events: none;

            .disabled-label {
              top: 0;
              left: 0;
              z-index: 3;
              color: $primary;
              background: #ffffff;
            }
          }

          &[data-is-static="true"] {
            pointer-events: none;

            .text {
              color: rgba($color: #333333, $alpha: 0.6);
            }
          }

          .progress-bar {
            height: 100%;
            top: 0;
            left: 0;
            background: rgb(128, 216, 140);
            background: linear-gradient(
              155deg,
              rgba(128, 216, 140, 1) 0%,
              rgba(109, 212, 152, 1) 30%,
              rgba(0, 212, 255, 1) 100%
            );
            z-index: 9;
            transition: all 300ms;
          }

          .icon {
            margin-right: 5px;
          }

          .text {
            font-size: 12px;
            font-weight: 500;
            color: $primary;
            text-overflow: ellipsis;
          }

          .progress-text {
            font-size: 14px;
            font-weight: 600;
            color: $primary;
            z-index: 10;
          }
        }

        .remove-link {
          font-size: 12px;
          font-weight: 500;
          color: #ea3325;
          margin-top: 20px;
          text-decoration: underline;
          width: max-content;
        }

        &:hover {
          .status {
            &[data-status="true"] {
              .remove-icon {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .checking-page {
      padding: 0 32px;

      .checking-title {
        font-size: 24px;
        font-weight: 700;
        color: #333333;
        margin: 25px 0 15px;
      }

      .checking-text {
        font-size: 15px;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
        margin-bottom: 28px;

        &:last-of-type {
          margin-bottom: 0px;
        }
      }
    }

    &.agreement-fix {
      padding: 30px 25px;
      .agreement-page-title {
        font-size: 18px;
        font-weight: 700;
        color: #333333;
        margin: 0 0 15px;
        letter-spacing: -0.6px;
        padding: 0 15px;

        @media (min-width: 768px) {
          font-size: 24px;
          letter-spacing: 0;
        }
      }

      .agreement-text {
        max-height: 50vh;
        min-height: 500px;
        overflow: auto;
        margin-bottom: 20px;
        padding: 0 15px;
      }
    }
  }
}
