@import "../assets/mixins";

.ModalActiveBody {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.Modal {
  width: 100vw;
  position: fixed;
  height: 100vh;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(#11171d, 0.6);
    z-index: 2;
  }

  .ContentHolder {
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .MobileCloseBtn {
      width: 29px;
      height: 29px;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 6;
      display: none;

      @include media-sm() {
        display: block;
      }
    }

    .Content {
      width: 100%;
      max-width: 475px;
      height: auto;
      max-height: 70vh;
      z-index: 3;
      padding: 25px;
      background: #fff;
      border-radius: 10px;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      display: flex;
      flex-direction: column;

      @include media-sm() {
        max-width: 80vw;
        max-height: 85vh;
        overflow: auto;
      }
    }

    .CloseBtn {
      margin-left: 10px;
      height: 42px;
      width: 42px;
      background: rgba(#fff, 0.2);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px;
      margin-right: -52px;

      @include media-sm() {
        display: none;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}
