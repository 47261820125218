@import "../utils/assets/typography";
@import "../utils/assets/mixins";

.header {
  background: rgb(231, 91, 43);
  background: linear-gradient(
    115deg,
    rgba(231, 91, 43, 1) 0%,
    rgba(254, 185, 132, 1) 100%
  );
  height: 356px;
  margin-bottom: -240px;
  position: relative;

  @include media-sm() {
    margin-bottom: -260px;
  }

  .logo-name-divider {
    height: 40px;
    width: 1px;
    background: rgba(#fff, 0.3);
    margin: auto 12px;
    display: inline-block;

    @include media-sm() {
      margin: auto 8px;
    }
  }

  .sup-hub {
    color: #fff;
    font-size: 24px;

    span {
      font-weight: lighter;

      span {
        font-weight: bold;
      }
    }

    @include media-sm() {
      font-size: 19.2px;
    }
  }

  @include media-sm() {
    .col {
      &.navbar-brand {
        margin: 0;
        .navbar-brand {
          svg {
            width: 76px;
          }
        }
      }
    }
  }

  .nav-menu {
    width: auto;
    position: relative;
    z-index: 2;

    @include media-sm-min {
      overflow-x: auto;
      overflow-y: hidden;
    }

    @include media-sm {
      width: 100%;
      flex-direction: column;
      z-index: 44;
    }

    .nav-menu-content {
      width: auto;
      list-style: none;

      .nav-menu-item {
        white-space: nowrap;
      }

      &#extraRoutes {
        @include media-sm {
          width: 100%;
          margin-top: 6px;

          .nav-menu-item {
            width: 100%;
            margin: 0;
            white-space: nowrap;

            .nav-menu-link {
              width: 100%;
              display: block;
              padding: 14px;
              background: rgba(#fff, 0.15);
              border-radius: 20px;
              font-size: 13px;
              font-weight: 500;

              @include media-sm {
                padding: 10px 14px;
              }

              svg {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }

      .nav-menu-item {
        margin-right: 0.5rem;
        .nav-menu-link {
          padding: 12px 16px;
          border-radius: 50px;
          color: #fff;
          font-size: 17px;

          &:hover,
          &:active,
          &:focus {
            text-decoration: none;
          }

          &.active {
            font-weight: $font-weight-bold;
            background: rgba($color: #fff, $alpha: 0.15);
          }
        }
      }
    }

    .nav-menu-mobile {
      width: 100%;
      background: rgba(#ffffff, 0.15);
      border-radius: 20px;
      padding: 13px 18px 13px 15px;
      margin-top: 23px;
      border: none;
      .label {
        text-transform: uppercase;
        color: #fff;
        font-size: 13px;
        font-weight: 500;
      }

      .currPage {
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        svg {
          margin-left: 11px;
          transform: rotate(0);
          transition: transform 200ms;

          &.menu-active {
            transform: rotate(-180deg);
          }
        }
      }
    }

    .nav-menu-mobile-content {
      width: 100%;
      position: absolute;
      z-index: 44;
      margin-top: 9px;
      border-radius: 10px;
      background: #ffffff;
      box-shadow: 0 21px 109px rgba(0, 0, 0, 0.14);
      list-style: none;
      top: calc(23px + 44px);

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -15px;
        background: transparent;
        width: 100vw;
        height: calc(100vh - 72px - 44px - 23px - 9px);
        z-index: 2;
      }

      .nav-menu-item {
        border-bottom: 1px solid #eeeeee;
        position: relative;

        &:after {
          content: "";
          width: 17px;
          height: 17px;
          background: url("../utils/assets/right_arrow.svg") no-repeat center;
          display: block;
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 0;
        }
        &:last-child {
          border: none;
        }
        .nav-menu-link {
          font-size: 16px;
          font-weight: 500;
          padding: 16px 20px;
          z-index: 2;

          &:hover,
          &:active,
          &:focus {
            text-decoration: none;
          }

          &.active {
            font-weight: $font-weight-bold;
            color: #f18351;
          }
        }
      }
    }
  }

  .user-area-holder {
    @include media-sm() {
      flex-basis: 20%;
    }

    .approvalProgress {
      a {
        background: #f3f3f3;
        border-radius: 50%;
        padding: 6px;
        margin-right: 20px;
        box-shadow: 0 0 8px 4px rgba($color: #000000, $alpha: 0.05);
        .disabled-tooltip {
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          cursor: pointer;

          .bl-tooltip-content {
            width: max-content;
          }
        }

        svg {
          width: 24px;
          height: 24px;

          path {
            fill: rgb(251, 206, 105);
            fill: linear-gradient(
              126deg,
              rgba(251, 206, 105, 1) -50%,
              rgba(255, 198, 74, 1) -5%,
              rgba(240, 176, 36, 1) 50%
            );
          }

          &.inspecting {
            path {
              fill: #80d88c;
              fill: linear-gradient(
                130deg,
                rgba(128, 216, 140, 1) -20%,
                rgba(109, 212, 152, 1) 30%,
                rgba(56, 202, 187, 1) 90%
              );
            }
          }
        }
      }
    }

    .user-area {
      width: auto;

      .notifications {
        position: relative;
        margin-right: calc(20px + 20px);

        &.new-notification {
          &:before {
            content: attr(data-notification-count);
            width: 20px;
            height: 20px;
            position: absolute;
            top: 0;
            right: 0;
            font-weight: $font-weight-bold;
            font-size: 12px;
            color: #fff;
            background: rgba($color: #fff, $alpha: 0.2);
            z-index: 2;
            padding: 5px;
            border-radius: 50%;
            transform: translate(85%, -50%);
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .user {
        cursor: pointer;
        color: #fff;

        .avatar {
          width: 40px;
          height: 40px;
          border-radius: 50%;

          @include media-sm() {
            margin-right: 5px;
          }

          .complete-warning {
            width: 24px;
            height: 24px;
            padding: 5px;
            background: rgb(251, 206, 105);
            background: linear-gradient(
              144deg,
              rgba(251, 206, 105, 1) -50%,
              rgba(240, 176, 36, 1) 150%
            );
            bottom: 0;
            right: 0;
            transform: translate(20%, 30%);
            box-shadow: 0 0 16px 4px rgba($color: #000000, $alpha: 0.1);

            &:hover {
              z-index: 45;
            }

            img {
              width: 100%;
            }

            &[data-status="inspecting"] {
              padding: 0 7px;
              background: rgb(37, 198, 252);
              background: linear-gradient(
                144deg,
                rgba(37, 198, 252, 1) -50%,
                rgba(75, 160, 255, 1) 150%
              );

              svg {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }

            .bl-tooltip {
              .bl-tooltip-icon {
                width: 100%;
                height: auto;
              }

              .bl-tooltip-content {
                width: max-content;
                max-width: 210px;
                text-align: center;

                @include media-sm() {
                  transform: translate(calc(-100% + 15px), calc(100% + 10px));

                  &:after {
                    left: auto;
                    right: 0;
                    transform: translate(-100%, -100%);
                  }
                }
              }
            }
          }
        }
        img {
          height: 40px;
          border-radius: 50%;
        }

        .welcome {
          h6 {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }

      .dropdown-holder {
        width: 290px;
        top: calc(100% + 10px);
        right: 0;
        z-index: 45;
        border-radius: 6.87px;
        background: #ffffff;
        box-shadow: 0 21px 109px rgba($color: #000000, $alpha: 0.14);

        @include media-sm() {
          top: calc(100% + 24px);
          width: calc(100vw - 20px);
          right: -5px;
        }

        .nav {
          .nav-item {
            border-bottom: 1px solid #eeeeee;
            position: relative;

            &:after {
              content: "";
              width: 17px;
              height: 17px;
              background: url("../utils/assets/right_arrow.svg") no-repeat
                center;
              display: block;
              position: absolute;
              right: 15px;
              top: 50%;
              transform: translateY(-50%);
              z-index: 0;
            }
            &:last-child {
              border: none;
              &:after {
                display: none;
              }
            }
            .nav-link {
              font-size: 16px;
              font-weight: 500;
              padding: 16px 20px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              position: relative;
              z-index: 2;

              .inner {
                font-size: 12px;
                font-weight: 500;
                color: #aaaaaa;
                margin-right: 15px;
              }

              .notification-badge {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: #fef4ef;
                font-size: 12px;
                font-weight: 700;
                color: #e75b2b;
                margin-right: 15px;
              }
            }
          }
        }
      }

      .notification-dropdown-holder {
        width: 349px;
        top: calc(100% + 10px);
        right: calc(40px + 104px - 5px);
        z-index: 4;
        border-radius: 6.87px;
        background: #ffffff;
        box-shadow: 0 21px 109px rgba($color: #000000, $alpha: 0.14);
        overflow: hidden;

        .notifications {
          margin: 0;
          max-height: 480px;
          overflow-y: auto;
          .notification {
            padding: 15px;

            .avatar {
              margin-right: 10px;
              width: 50px;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              overflow: hidden;
              img {
                width: 100%;
                border-radius: 50%;
              }
            }
            .content {
              width: 100%;

              .title {
                font-size: 13px;
                font-weight: 700;
                margin-bottom: 6px;
              }
              .text {
                margin-bottom: 12px;
                font-size: 15px;
                font-weight: 400;
                color: #0c0c0c;
                line-height: 20px;
                span {
                  font-weight: 700;
                }
              }

              .date {
                font-size: 13px;
                font-weight: 300;
                color: #656565;
                line-height: 17px;
              }
            }
            .divider {
              width: calc(100% - 50px - 10px - 14px);
              bottom: 0;
              right: 0;
              margin: 0;
            }

            &:last-child {
              .divider {
                display: none;
              }
            }
          }
        }

        .all-notifications {
          width: 100%;
          padding: 13px 0;
          background: #f8f8f8;
          color: #515151;
          font-size: 15px;
          font-weight: 500;
          text-transform: uppercase;
        }
      }
    }

    .guest-area {
      a {
        color: #fff;
        font-weight: 500;

        @include media-sm {
          padding: 0;
        }
      }
    }
  }

  &.landingPageFix {
    background: #fff;
    height: auto;
    margin-bottom: 0;
    padding: 0.5rem;

    .logo-name-divider {
      background: rgba($color: #000000, $alpha: 0.1);
    }

    .sup-hub {
      color: #000000;
    }

    .guest-area {
      a {
        color: #000000;
        font-weight: 500;
      }
      .arrow {
        svg {
          path {
            stroke: #282831;
          }
        }
      }
    }

    .user-area {
      z-index: 6;

      .feedback-icon {
        svg {
          path {
            fill: rgb(231, 91, 43);
          }
        }
      }

      .notifications {
        svg {
          path {
            fill: rgb(231, 91, 43);
          }
        }
        &.new-notification {
          &:before {
            background: rgba($color: #000000, $alpha: 0.25);
          }
        }
      }

      .user {
        color: #000000;

        .welcome {
          h6 {
            svg {
              path {
                fill: #000000;
              }
            }
          }
        }

        .arrow {
          svg {
            path {
              stroke: #282831;
            }
          }
        }
      }
    }
  }

  &.helpCenterPageFix {
    background: transparent;
    height: auto;
    margin-bottom: 0;
    padding: 0.5rem;
  }

  &.helpCenterDetailFix {
    @include media-sm-min {
      background: transparent;
      height: auto;
      margin-bottom: 0;
      padding: 0.5rem;

      .navbar-brand {
        svg {
          width: 100%;
          path {
            &:first-child {
              fill: #e75b2b;
            }

            &:last-child {
              fill: #000000;
            }
          }
        }
      }

      .logo-name-divider {
        background: rgba($color: #000000, $alpha: 0.1);
      }

      .sup-hub {
        color: #000000;
      }

      .guest-area {
        a {
          color: #000000;
          font-weight: 500;
        }
      }

      .user-area {
        z-index: 6;

        .notifications {
          svg {
            path {
              fill: rgb(231, 91, 43);
            }
          }
          &.new-notification {
            &:before {
              background: rgba($color: #000000, $alpha: 0.3);
            }
          }
        }

        .user {
          color: #000000;

          .welcome {
            h6 {
              svg {
                path {
                  fill: #000000;
                }
              }
            }
          }

          .arrow {
            svg {
              path {
                stroke: #282831;
              }
            }
          }
        }
      }
    }
    @include media-sm {
      height: 150px;
      margin-bottom: -105px;

      .navbar-brand {
        svg {
          width: 100%;
          path {
            &:first-child {
              fill: #ffffff;
            }

            &:last-child {
              fill: #ffffff;
            }
          }
        }
      }

      .guest-area {
        .icon-bg {
          fill: #ffffff;
          opacity: 1;
        }
      }
    }
  }

  &.notFoundFix {
    background: transparent;
    height: auto;
    margin-bottom: 0;
    padding: 0.5rem;

    .logo-name-divider {
      background: rgba($color: #000000, $alpha: 0.1);
    }

    .sup-hub {
      color: #000000;
    }

    .guest-area {
      a {
        color: #000000;
        font-weight: 500;
      }
    }
  }

  @include media-sm() {
    padding: 0 !important;
  }
}
