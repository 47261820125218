@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700,800&display=swap&subset=latin-ext");
@import url('https://fonts.googleapis.com/css2?family=Hammersmith+One&display=swap');

$font: normal normal 15px Montserrat, -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;

$font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;
$font-size: 15px;
$font-weight: normal;

$font-weight-light: light;
$font-weight-normal: 400;
$font-weight-bold: bold;
$font-weight-black: 900;

body {
  font: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
