.Stars {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .starsHolder {
    display: flex;
    align-items: center;
  }
  .rating {
    font-size: 13.5px;
    font-weight: 600;
    line-height: 20px;
    color: #f5a923;
    margin-left: 10.5px;
  }
}
