@import "../utils/assets/mixins";

.notFoundContainer {
  padding: 136px 0 0;
  margin-bottom: -3rem;
  margin-top: -136px;
  background: #ffffff;
  .notFoundFix {
    background: transparent;
    box-shadow: none;
    margin: 0;
  }
}

.NotFound {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 148px 0 133px;

  @include media-xs {
    padding: 30px 0 50px;
  }

  .icon {
    margin-bottom: 69px;

    * {
      width: 100%;
      height: 100%;
    }

    @include media-xs {
      margin-bottom: 40px;
      width: 120px;
      height: 120px;
    }
  }

  .title {
    font-size: 40px;
    font-weight: 600;
    color: #0c0c0c;
    line-height: 20px;
    margin-bottom: 26px;
    text-align: center;

    @include media-xs {
      font-size: 26px;
      text-align: center;
      line-height: 1;
    }
  }

  .text {
    font-size: 20px;
    font-weight: 400;
    color: #0c0c0c;
    line-height: 30px;
    margin-bottom: 60px;
    max-width: 545px;
    text-align: center;

    @include media-xs {
      font-size: 18px;
      text-align: center;
      max-width: 80%;
      margin-bottom: 30px;
    }
  }

  button {
    padding: 14px 68px;

    @include media-xs {
      padding: 14px 0;
      width: 80%;
    }
  }
}
