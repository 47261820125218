@import "../utils/assets/mixins";

.contact-us-page {
  padding: 77px 0 130px;

  @include media-md {
    padding: 30px 0 50px;
  }

  .left-side {
    padding-right: 66px;

    @include media-md {
      padding: 0;
    }

    .help-center-link {
      margin-bottom: 25px;

      .icon {
        margin-right: 15px;

        @include media-sm {
          width: 64px;
          height: 64px;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      .title {
        svg {
          margin-left: 14px;
        }
      }
    }

    .address-area {
      border-top: 1px solid #d8d8d8;
      display: grid;
      grid-template-columns: min-content 60%;
      padding-top: 5px;

      dt {
        font-size: 15px;
        font-weight: 500;
        color: #000000;
        padding: 10px 0 15px;
        line-height: 25.5px;
        margin-right: 15px;
        white-space: nowrap;
      }

      dd {
        font-size: 15px;
        font-weight: 400;
        color: #000000;
        line-height: 25.5px;
        border-bottom: 1px solid #d8d8d8;
        padding: 10px 0 15px;
        max-width: 300px;

        &:last-of-type {
          border-bottom: none;
        }
      }
    }

    .asterisk {
      font-size: 13px;
      font-weight: 500;
      color: #000000;
    }
  }

  .right-side {
    form {
      margin-top: 27px;

      textarea {
        height: 120px;
      }
    }
  }

  .title {
    font-size: 22px;
    font-weight: 400;
    color: #000000;
    height: 64px;

    @include media-md-min {
      font-size: 22px;
      height: 64px;
    }

    @include media-lg-min {
      font-size: 28px;
      height: 72px;
    }
  }
}
