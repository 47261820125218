@import "../utils/assets/mixins";

.bookingsFix {
  .page-area {
    @include media-sm {
      margin-top: 90px;
      padding: 10px 0;
    }
  }
}
#booking-results-area {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(#fff, 0.9);
    opacity: 0;
    z-index: -2;
    transition: opacity 300ms ease 0ms, z-index 0ms ease 300ms;
  }

  &.search-active {
    &:after {
      opacity: 1;
      z-index: 2;
      transition: opacity 300ms ease 10ms, z-index 0ms ease 0ms;
    }
  }

  &.search-finished {
    padding-bottom: 1.5rem;

    &:after {
      background: rgba(#fff, 1);
    }

    .bookings-area {
      display: none;
    }

    .pagination {
      display: none !important;
    }
  }

  &.searching-active {
    &:after {
      animation-name: searching;
      animation-duration: 1200ms;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(6, 0, 0.5, 1);
    }
  }

  .search-results {
    position: relative;
    z-index: 3;
  }
  .single-booking {
    .dates {
      .date {
        font-size: 15px;
        font-weight: 600;
        color: #000;

        @include media-sm {
          font-size: 13px;
          line-height: 22px;
        }

        span {
          font-weight: 300;
          color: #656565;
        }
      }
    }

    .booking-name {
      font-weight: 300;
      color: #333333;
      line-height: 16.5px;
    }
  }

  .search-no-result {
    padding: 119px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h3 {
      font-size: 24px;
      font-weight: 700;
      color: #17202a;
      line-height: 33.8px;
      margin-top: 27px;
      margin-bottom: 9px;
      text-align: center;

      @include media-sm {
        font-size: 22px;
      }
    }

    p {
      font-size: 20px;
      font-weight: 300;
      color: #2d3541;
      line-height: 33.8px;
      text-align: center;
      max-width: 484px;

      @include media-sm {
        font-size: 18px;
        max-width: 250px;
        line-height: 28.1px;
      }
    }
  }
}

#booking-detail {
  .content {
    padding: 0;
    max-height: 90vh;
    max-width: 786px;

    @include media-xs {
      max-width: 100vw;
    }

    .booking-detail {
      @include media-sm {
        width: 90vw;
        height: 90vh;
        display: flex;
        flex-direction: column;
      }
      .head-area {
        background: #fef4ef;
        padding: 20px;
        border-radius: 10px 10px 0 0;

        @include media-sm {
          padding: 14px 11px;
          flex-basis: 20%;
        }

        .info-area {
          .user-avatar {
            width: 48px;
            height: 48px;
          }

          .info {
            .user {
              font-size: 20.4px;
              font-weight: 600;
              color: #000000;
            }

            .other-info {
              font-size: 13.2px;
              font-weight: 300;
              color: #000000;
            }
          }
        }

        .settings-area {
          .setting-button {
            padding: 14px 10px;
            width: 140px;
            font-size: 16px;
          }

          .cancel-button {
            font-size: 16px;
          }
        }
      }

      .content-area {
        padding: 20px;

        @include media-sm {
          overflow: auto;
          padding: 14px;
        }

        .booking-info-section {
          .section-title {
            font-size: 13px;
            font-weight: 700;
            color: #515151;
            text-transform: uppercase;
          }

          .details-list {
            .detail-content {
              position: relative;

              .title {
                font-size: 15px;
                font-weight: 300;
                color: #000;
              }

              .content {
                font-size: 15px;
                font-weight: 600;
                color: #e75b2b;
                position: relative;
                display: flex;
                align-items: center;
              }
            }
          }

          .coupon-area {
            height: 72px;
            background: rgb(231, 91, 43);
            background: linear-gradient(
              105deg,
              rgba(231, 91, 43, 1) 27%,
              rgba(254, 185, 132, 1) 100%
            );
            padding: 25px 30px 25px 16.5px;
            position: relative;
            border-radius: 8px;

            &:before {
              content: "";
              width: 19px;
              height: 19px;
              position: absolute;
              left: 0;
              background: #fff;
              transform: translateX(-50%);
              border-radius: 50%;
            }

            &:after {
              content: "";
              width: 19px;
              height: 19px;
              position: absolute;
              right: 0;
              background: #fff;
              transform: translateX(50%);
              border-radius: 50%;
            }

            .title {
              font-size: 15px;
              font-weight: 300;
              color: #ffffff;
            }

            .content {
              font-size: 17px;
              font-weight: 700;
              color: #fff;
            }
          }

          .participants-area {
            .participant {
              .avatar {
                width: 50px;
                height: 50px;
              }

              .user-info {
                width: calc(100% - 50px - 1rem);

                .name {
                  font-size: 17px;
                  font-weight: 600;
                  color: #e75b2b;
                }

                .infos {
                  .info {
                    .title {
                      font-size: 12px;
                      font-weight: 400;
                      color: #656565;
                    }

                    .content {
                      font-size: 12px;
                      font-weight: 600;
                      color: #000000;
                    }
                  }
                }
              }
            }
          }

          .exp-info-area {
            .product-info {
              .title {
                font-size: 17px;
                font-weight: 600;
                color: #000000;
              }

              .code {
                font-size: 13px;
                font-weight: 500;
                color: #515151;
              }
            }
            .unit-info {
              .unit-name {
                font-size: 16px;
              }

              .unit-count {
                font-size: 14px;
                font-weight: 600;
              }
            }
          }
        }

        .request-help-section {
          background: #eff9fe;
          padding: 16px;
          border-radius: 4px;
          .desc {
            font-size: 13px;
            font-weight: 600;
            color: #44a7fe;
          }

          .request-button {
            padding: 12px 30px;
            font-size: 16px;
            font-weight: 600;
            white-space: nowrap;
          }
        }

        .settings-area {
          margin-bottom: 20px;
          position: relative;

          &:before {
            content: "";
            width: calc(100% + 28px);
            height: 1px;
            position: absolute;
            top: 0;
            left: -14px;
            background: #eeeeee;
          }
          .setting-button {
            padding: 14px 10px;
            width: 100%;
            font-size: 16px;
            margin-top: 12px;
          }

          .cancel-button {
            font-size: 16px;
            margin-top: 5px;
            margin-bottom: -6px;
          }
        }
      }

      .cancel-popup {
        top: 0;
        left: 0;
        z-index: 7;

        .overlay {
          width: 100vw;
          height: 100vh;
          position: static;
          top: 0;
          left: 0;
          background: rgba(#11171d, 0.4);
          z-index: 1;
          display: none;
        }

        @include media-sm {
          top: auto !important;
          bottom: 0 !important;

          .overlay {
            display: block;
          }
        }

        .popup-holder {
          width: 378px;
          padding: 25px 30px;
          background: #fff;
          border-radius: 6.87px;
          box-shadow: 0 21px 109px rgba($color: #000000, $alpha: 0.13);

          @include media-sm {
            width: 100vw;
            border-radius: 20px 20px 0 0;
            position: relative;
            z-index: 3;
          }
          h6 {
            font-size: 22px;
            font-weight: 800;
            color: #000000;
          }

          .other-date-area {
            flex-basis: calc(100% - 30px - 9px);
            .col {
              flex-basis: 100%;
              .form-group {
                width: 100%;
                &:first-child {
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }

      .control-popups-enter {
        opacity: 0;
        transition: opacity 300ms ease;
      }

      .control-popups-enter-active {
        opacity: 1;
      }

      .control-popups-exit {
        opacity: 1;
        transition: opacity 300ms ease;
      }

      .control-popups-exit-active {
        opacity: 0;
      }

      @include media-sm {
        .control-popups-enter {
          opacity: 0;
          transform: translateY(50%) !important;
          transition: opacity 300ms ease, transform 300ms ease;
        }

        .control-popups-enter-active {
          opacity: 1;
          transform: translateY(0%) !important;
        }

        .control-popups-enter-done {
          transform: translateY(0) !important;
        }

        .control-popups-exit {
          opacity: 1;
          transform: translateY(0%) !important;
          transition: opacity 300ms ease, transform 300ms ease;
        }

        .control-popups-exit-active {
          opacity: 0;
          transform: translateY(50%) !important;
        }
      }
    }
  }

  .on-hold-success-fix {
    .content {
      padding: 45px 45px 35px;
      max-width: 400px;
    }
  }
}

.bookingSearchArea {
  position: relative;
  @include media-sm {
    width: 100%;
    margin-top: 6px;
    padding: 10px 14px;
    background: rgba(#fff, 0.15);
    border-radius: 20px;
    flex-direction: row-reverse;
  }

  label {
    margin: 0 10px;
    cursor: pointer;

    @include media-sm {
      margin-right: 0;
    }

    svg {
      @include media-sm {
        width: 18px;
        height: 18px;
      }
    }
  }

  input {
    background: transparent;
    border: none;
    color: #fff;
    outline: none;
    @include media-sm {
      order: 3;
      font-size: 13px !important;
    }

    &::placeholder {
      opacity: 1;
      color: rgba(#ffffff, 0.4);
      font-size: 12px;
    }
  }

  a {
    color: #fff;
    white-space: nowrap;

    @include media-sm {
      order: 2;
      font-size: 13px;
    }

    &:hover,
    &:active,
    &:visited,
    &:focus {
      color: #fff;
    }
  }

  .search-box-enter {
    opacity: 0;
    width: 0px;
    transition: opacity 400ms ease, width 400ms ease;
  }

  .search-box-enter-active {
    opacity: 1;
    width: 235px;

    @include media-sm {
      width: 245px;
    }
  }
  .search-box-enter-done {
    width: 235px;
    @include media-sm {
      width: 100%;
    }
  }

  .search-box-exit {
    opacity: 1;
    width: 190px;
    transition: opacity 400ms ease, width 400ms ease;

    @include media-sm {
      width: 100%;
    }
  }

  .search-box-exit-active {
    opacity: 0;
    width: 0;
  }
}

@keyframes searching {
  0% {
    background: rgba(255, 255, 255, 0.9);
  }

  50% {
    background: rgba(255, 255, 255, 0.7);
  }

  100% {
    background: rgba(255, 255, 255, 0.9);
  }
}
