$onColor: #34c759;
$offColor: #999999;

.switchHolder {
  .settingsSwitch {
    width: 100%;

    .switcher {
      display: none;
    }

    .switchHolder {
      width: 48px;
      height: 27px;
      background: #eee;
      border: #999;
      border-radius: 25px;
      transition: background 200ms cubic-bezier(0.63, 0.01, 0.41, 1);
      cursor: pointer;
      overflow: hidden;

      &:before {
        content: "";
        width: 24px;
        height: 24px;
        background: $offColor;
        border-radius: 50%;
        transition: transform 200ms cubic-bezier(0.63, 0.01, 0.41, 1);
        transform: translateX(calc(-50% + 4px));
        transform-origin: center;
        box-shadow: 0 3px 8px rgba(#000000, 0.15);
      }
    }

    .switcher:checked ~ .switchHolder {
      background: $onColor;

      &:before {
        transform: translateX(calc(50% - 0px));
        background: #eee;
      }
    }
  }

  .sub {
    width: 100%;
    border-top: 1px solid #e6e6e6;
    padding-top: 1rem;
    font-size: 13px;
    font-style: italic;
    color: #999999;
  }
}
