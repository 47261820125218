@keyframes loadingDots {
  0% {
    background: #ffffff;
    transform: scale(0.4);
  }

  50% {
    background: $primary;
    transform: scale(1);
  }

  100% {
    background: #ffffff;
    transform: scale(0.4);
  }
}

@keyframes pulseLogo {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes popAndShake {
  0% {
    transform: scale(0.4) rotate(0deg);
    box-shadow: 0px 0px 0px rgba($color: #000000, $alpha: 0);
    z-index: 99;
  }

  35% {
    transform: scale(1.2) rotate(-25deg);
    box-shadow: 0px 0px 7px 1px rgba($color: #000000, $alpha: 0.15);
    z-index: 99;
  }

  65% {
    transform: scale(1.2) rotate(25deg);
    box-shadow: 0px 0px 7px 1px rgba($color: #000000, $alpha: 0.15);
    z-index: 99;
  }

  100% {
    transform: scale(1) rotate(0deg);
    box-shadow: 0px 0px 0px rgba($color: #000000, $alpha: 0);
    z-index: 1;
  }
}
