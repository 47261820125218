@import "../utils/assets/mixins";

.profileFix {
  @include media-md {
    margin-top: 20px;
  }
}

.leftNav {
  @include media-md {
    transform: translateX(-100%);
    position: absolute !important;
    top: 0;
    transition: transform 300ms;
    z-index: 4;
    background: #fff;

    &.navActive {
      transform: translateX(0);
      position: relative !important;
    }
  }
}

.toggleNavButton {
  position: absolute;
  top: 15px;
  left: 30px;
  z-index: 5;
  font-size: 15px;
  font-weight: 500;
  transition: all 300ms;
  align-items: center;
  display: none;

  svg {
    margin-right: 5px;
  }

  @include media-md {
    display: flex;

    &.navActive {
      z-index: 2;
    }
  }
}

.rightContent {
  @include media-md {
    padding-top: 35px;

    &.navActive {
      position: absolute !important;
      left: 0;
      top: 0;
    }
  }
}

.profileNavArea {
  padding: 35px 20px;
  width: 100%;
  height: 100%;
  position: relative;

  @include media-md {
    padding: 35px 0;

    &:after {
      display: none;
    }
  }

  &:after {
    content: "";
    width: 65px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(249, 249, 249, 1) 100%
    );
  }
}

.userArea {
  width: 100%;
  position: relative;
  z-index: 2;

  .avatar {
    width: 150px;
    height: 150px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 0 24px 12px rgba($color: #000000, $alpha: 0.05);

    img {
      border-radius: 50%;
    }
  }

  .supplierName {
    font-size: 27px;
    font-weight: 600;
    color: #e75b2b;
  }

  .supplierDesc {
    font-size: 16.5px;
    font-weight: 400;
    opacity: 0.5;
    color: #000000;
    cursor: default;
  }
}

.approvalProgress {
  width: calc(100% - 30px);
  margin: 0 auto;
  background: rgb(251, 206, 105);
  background: linear-gradient(
    126deg,
    rgba(251, 206, 105, 1) -50%,
    rgba(255, 198, 74, 1) -5%,
    rgba(240, 176, 36, 1) 50%
  );
  border-radius: 10px;
  position: relative;
  z-index: 3;
  padding: 10px 12px;

  &[data-completed="true"] {
    background: rgb(128, 216, 140);
    background: linear-gradient(
      130deg,
      rgba(128, 216, 140, 1) -20%,
      rgba(109, 212, 152, 1) 30%,
      rgba(56, 202, 187, 1) 90%
    );
  }

  .infoArea {
    width: 100%;

    .approvalText {
      font-size: 12px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 6px;
    }

    .progressBar {
      width: 100%;
      height: 14px;
      background: rgba($color: #ffffff, $alpha: 0.3);
      border-radius: 5px;

      &:before {
        content: "";
        width: 0%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #ffffff;
        border-radius: 5px;
        transition: width 200ms;
      }

      &:after {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
      }

      @for $var from 0 to 101 {
        &[data-progress="#{$var}"] {
          &:before {
            width: $var#{"%"};
          }

          &:after {
            content: "#{$var}%";
            left: $var#{"%"};

            @if $var <= 85% {
              transform: translate(4px, -50%);
            } @else {
              transform: translate(calc(-100% - 5px), -50%);
              color: #f0b024;
            }
          }
        }
      }
    }
  }

  &[data-completed="true"] {
    .infoArea {
      .progressBar {
        &:after {
          color: #38cabb;
        }
      }
    }
  }

  a {
    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.profileNav {
  position: relative;
  z-index: 2;

  .profileNavLink {
    padding: 15px;
    position: relative;
    font-size: 15px;
    font-weight: 500;

    &.active {
      color: #e75b2b;

      .arrowIcon {
        stroke: #e75b2b;
      }
    }

    &:after {
      content: "";
      width: calc(100% - 48px - 15px);
      height: 1px;
      background: #e7e7e7;
      position: absolute;
      right: 15px;
      bottom: 0;

      @include media-md {
        width: calc(100% - 48px);
        right: 0;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    .icon {
      width: 24px;
      height: 24px;
    }
  }
}

.pageContent {
  padding: 53px 60px 53px 30px;
  position: relative;

  @include media-md {
    padding: 15px 20px 30px;
  }

  .pageTitle {
    font-size: 29px;
    font-weight: 400;
    color: #333;

    @include media-md {
      font-size: 26px;
      flex-direction: column;
      align-items: flex-start !important;
    }

    label {
      font-size: 14px;
      font-weight: 600;
      color: #e75b2b;
      cursor: pointer;

      @include media-md {
        margin-top: 13px;
      }
    }

    .backButton {
      font-size: 16px;
      color: rgba(#000000, 0.6);
      cursor: pointer;
      margin-left: 10px;

      @include media-md {
        margin-top: 13px;
        margin-left: 0;
      }
    }
  }

  .pageDesc {
    font-size: 15px;
    font-weight: 400;
  }

  .postCodeInfo {
    font-size: 14px;
    font-weight: 400;

    a {
      font-weight: 600;
    }
  }

  .profileNavArea {
    padding: 0;

    &:after {
      display: none;
    }

    @include media-md {
      margin-top: 15px !important;
    }

    .profileNav {
      .profileNavLink {
        padding: 15px 0px;

        color: #1b1b1b;

        &:after {
          left: 0;
          width: 100%;
        }

        &:last-child {
          &:after {
            display: block;
          }
        }
      }
    }
  }

  .thingsToConsiderForPassword {
    font-size: 13px;
    strong {
      display: block;
      margin: 15px 0 13px;
    }

    ul {
      margin: 0 0 13px;
      padding: 0;
      list-style-type: none;

      li {
        margin-top: 13px;

        &:before {
          content: "-";
          margin-right: 5px;
        }

        ul {
          padding-left: 15px;
        }
      }
    }
  }

  .documentNav {
    border-bottom: 1px solid #e7e7e7;

    @include media-md {
      width: calc(100% + 40px);
      margin-left: -20px;
    }

    a {
      padding: 14px;
      transition: border 200ms;
      border-bottom: 2px solid transparent;
      position: relative;

      @include media-md {
        font-size: 16px;
        flex-basis: 50%;
        text-align: center;
      }

      &:after {
        content: "";
        width: 100%;
        height: 2px;
        background: #e75b2b;
        left: 0;
        bottom: -2px;
        position: absolute;
        transform: scaleX(0);
        transition: transform 200ms;
      }

      &.active {
        &:after {
          transform: scaleX(1);
        }
      }

      &:first-child {
        &:after {
          transform-origin: right;
        }
      }

      &:last-child {
        &:after {
          transform-origin: left;
        }
      }
    }
  }

  &.completeAccount {
    .progressHolder {
      margin-top: 20px;
      width: 100%;
      height: 10px;
      border-radius: 5px;
      background: #eaeaea;
      position: relative;

      &:before {
        content: "";
        width: 0%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgb(251, 206, 105);
        background: linear-gradient(
          126deg,
          rgba(251, 206, 105, 1) -50%,
          rgba(255, 198, 74, 1) -5%,
          rgba(240, 176, 36, 1) 50%
        );
        border-radius: 5px;
        transition: width 200ms;
      }

      &:after {
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translate(-50%, calc(100% + 6px));
        font-size: 15px;
        font-weight: 700;
        color: #f2b228;
      }

      @for $var from 0 to 101 {
        &[data-progress="#{$var}"] {
          &:before {
            width: $var#{"%"};
          }

          &:after {
            content: "#{$var}%";
            left: $var#{"%"};

            @if $var == 0 {
              transform: translate(0px, calc(100% + 6px));
            }
          }
        }
      }

      &[data-progress="100"] {
        &:before {
          background: rgb(128, 216, 140);
          background: linear-gradient(
            130deg,
            rgba(128, 216, 140, 1) -20%,
            rgba(109, 212, 152, 1) 30%,
            rgba(56, 202, 187, 1) 90%
          );
        }

        &:after {
          color: #45cdb3;
        }
      }
    }

    .progressList {
      margin-top: 65px;

      .singleProgress {
        width: 100%;
        background: #f8f8f8;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        padding: 15px 5px 15px 15px;
        position: relative;

        &[data-is-disabled="true"] {
          cursor: default !important;

          .disabledTooltip {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }

          a {
            display: none;
          }
        }

        .checkmark {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          border: 1.5px solid #9b9b9b;
          margin-right: 10px;

          svg {
            display: none;
            width: 100%;
            height: 100%;
          }

          &[data-is-completed="true"] {
            border: none;

            svg {
              display: flex;
            }
          }
        }

        .infoArea {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          width: calc(100% - 36px - 10px);

          .title {
            font-size: 16px;
            font-weight: 600;
            color: #000000;
            margin-bottom: 5px;
          }

          .desc {
            font-size: 14px;
            font-weight: 500;
            color: #aaaaaa;
            font-style: italic;
          }
        }

        a {
          svg {
            width: 22px;
          }
        }
      }
    }

    .sendForApprovalButton {
      max-width: 375px;
    }

    .contractContent {
      font-size: 16px;
    }

    .acceptContractButton {
      max-width: 375px;
    }
  }
}

.list {
  .SingleDocument {
    padding: 13px 0;
    border-bottom: 1px solid #e7e7e7;

    .DocInfoArea {
      width: calc(100% - 40px);
      overflow: hidden;

      h3 {
        font-size: 17px;
        color: #e75b2b;
        font-weight: 600;
        margin-bottom: 0;
      }

      h6 {
        font-size: 12px;
        font-weight: 600;
        margin: 0;

        span {
          font-weight: 300;
          color: #656565;
        }
      }
    }
  }
}

.modalTitle {
  font-size: 29px;
  font-weight: 400;
  color: #333;

  @include media-md {
    font-size: 26px;
  }
}

.documentInputLabelFix {
  @include media-md {
    padding: 14px 24px !important;
  }

  button {
    width: 26px;
    height: 26px;
    right: 15px;
  }
}

.DocumentDetailModal {
  .content {
    max-width: 80vw;
    max-height: 90vh;
  }
}
