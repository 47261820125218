.bl-tooltip {
  cursor: default;
  .bl-tooltip-icon {
    width: 17px;
    height: 17px;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .bl-tooltip-content {
    background: rgba($color: #000000, $alpha: 0.8);
    padding: 11px 20px;
    border-radius: 9px;
    width: 260px;
    opacity: 0;
    z-index: -1;
    transition: opacity 300ms ease 0, z-index 0ms ease 310ms;

    &:after {
      content: "";
      width: 0px;
      height: 0px;
      border: 5px solid transparent;
      position: absolute;
    }

    &,
    * {
      font-size: 13px;
      font-weight: 400;
      color: #ffffff;
      text-transform: none;
    }
  }

  &.top {
    .bl-tooltip-content {
      top: 0;
      left: 50%;
      transform: translate(-50%, calc(-100% - 10px));
      &:after {
        border-top-color: rgba($color: #000000, $alpha: 0.8);
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
      }
    }
  }

  &.bottom {
    .bl-tooltip-content {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, calc(100% + 10px));
      &:after {
        border-bottom-color: rgba($color: #000000, $alpha: 0.8);
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
      }
    }
  }

  &.right {
    .bl-tooltip-content {
      top: 50%;
      right: 0;
      transform: translate(calc(100% + 10px), -50%);
      &:after {
        border-right-color: rgba($color: #000000, $alpha: 0.8);
        top: 50%;
        left: 0;
        transform: translate(-100%, -50%);
      }
    }
  }

  &.left {
    .bl-tooltip-content {
      top: 50%;
      left: 0;
      transform: translate(calc(-100% - 10px), -50%);
      &:after {
        border-left-color: rgba($color: #000000, $alpha: 0.8);
        top: 50%;
        right: 0;
        transform: translate(100%, -50%);
      }
    }
  }

  &:hover {
    z-index: 99;
    .bl-tooltip-content {
      opacity: 1;
      z-index: 7;
      transition: opacity 300ms ease 10ms, z-index 0ms ease 0ms;
    }
  }
}
