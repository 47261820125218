@import "../utils/assets/mixins";

footer {
  width: 100%;
  background: #f8f8f8;
  padding: 0.5rem;

  @include media-sm() {
    padding: 0.5rem 0;
    position: relative;
    z-index: 5;
  }

  .col {
    .copyright {
      font-size: 13px;
      color: #a2a2a2;
      line-height: 25px;
      @include media-sm() {
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-top: 6px;
      }
    }

    .navbar {
      width: 100%;

      @include media-sm() {
        padding: 0.5rem 0;
        margin-top: 8px;
      }

      .navbar-collapse {
        .navbar-nav {
          @include media-sm() {
            flex-direction: row;
            justify-content: center;
            width: 100%;
          }
          .nav-item {
            @include media-sm() {
              margin-right: 10px;

              &:last-child {
                margin: 0;
              }
            }
            .nav-link {
              font-size: 13px;
              color: #a2a2a2;
              border-bottom: 1px solid #d8d8d8;
              padding: 0 0 7px !important;
              margin: 0.5rem;
              transition: padding 200ms;
              @include media-sm() {
                font-size: 12px;
                margin: 0;
              }

              &.active,
              &:hover {
                padding-bottom: 2px !important;
              }
            }
          }
        }
      }
    }

    &.ccs {
      justify-content: flex-end;

      @include media-sm() {
        justify-content: center;
        margin-top: 16px;
        margin-bottom: 5px;
      }
    }
  }
}
