@import "../utils/assets/mixins";

.Landing {
  width: 100%;

  @include media-sm {
    overflow-x: hidden;
  }

  .landingNav {
    width: 100%;
    padding: 15px 0;
    position: relative;
    top: 0px;
    z-index: 5;

    @include media-sm {
      cursor: pointer;
    }

    &:before {
      content: "";
      width: calc(100% + 30px);
      height: 100%;
      top: 0;
      left: -15px;
      position: absolute;
      background: rgb(231, 91, 43);
      background: linear-gradient(
        115deg,
        rgba(231, 91, 43, 1) 0%,
        rgba(254, 185, 132, 1) 100%
      );
      z-index: -1;
    }

    .titleArea {
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      text-transform: uppercase;
      height: 100%;

      @include media-sm {
        width: 100%;
      }
    }

    .navMenu {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      list-style: none;
      padding: 0;
      margin: 0;

      @include media-sm {
        height: auto;
        width: 100%;
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        background: #fff;
        flex-direction: column;
        align-items: flex-start;
        overflow-x: auto;
        border-radius: 10px;
        box-shadow: 0 21px 109px rgba(0, 0, 0, 0.34);
        opacity: 0;
        z-index: -3;
        transform: translateY(-50%);
        transition: opacity 250ms ease 0ms, transform 400ms ease 0,
          z-index 0ms ease 310ms;

        &::-webkit-scrollbar {
          display: none;
        }

        &.active {
          opacity: 1;
          z-index: 23;
          transform: translateY(0%);
          transition: all 300ms ease 10ms, z-index 0ms ease 0ms;
        }
      }

      li {
        margin: 0 15px;
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;

        @include media-sm {
          width: 100%;
          color: #000;
          border-bottom: 1px solid #eeeeee;
          position: relative;
          margin: 0;

          a {
            font-size: 16px;
            font-weight: 500;
            padding: 16px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            z-index: 2;
          }
        }
      }
    }
  }

  .banner {
    position: relative;
    padding: 150px 0 115px 150px;
    height: 780px;

    @include media-lg {
      padding: 80px 0 85px 100px;
    }

    @include media-md {
      padding: 80px 0 85px 40px;
      height: 645.8px;
    }

    @include media-sm {
      padding: 80px 30px 85px;
      height: 457px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    @include media-xs {
      padding: 0 30px 22px;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &:before {
      content: "";
      width: 100vw;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgb(255, 251, 250);
      background: linear-gradient(
        90deg,
        rgba(255, 251, 250, 1) 0%,
        rgba(255, 246, 243, 1) 100%
      );
      z-index: -1;

      @include media-sm {
        left: 0;
      }
    }

    .mobileImages {
      width: calc(100% + 60px);
      margin-bottom: 70px;

      .img {
        width: 295px;
        height: 100%;
      }

      .dots {
        position: absolute;
        left: 50%;
        bottom: -10%;
        transform: translateX(calc(-50% - 45px)) translateY(calc(-50% + 85px));
        z-index: -1;
        transform-origin: center;
        bottom: 0;
      }
    }

    .headline {
      width: 50%;
      font-size: 44px;
      font-weight: 800;
      line-height: 1.4;
      background: rgb(231, 91, 43);
      background: linear-gradient(
        125deg,
        rgba(231, 91, 43, 1) 0%,
        rgba(254, 185, 132, 1) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
      margin-bottom: 20px;
      cursor: default;

      @include media-md {
        font-size: 36px;
      }

      @include media-sm {
        width: 50%;
        font-size: 21px;
      }

      @include media-xs {
        width: 100%;
        font-size: 32px;
      }
    }

    .subline {
      width: 50%;
      padding-right: 140px;
      font-size: 20px;
      font-weight: 300;
      color: #000000;
      line-height: 28px;
      cursor: default;

      @include media-sm {
        font-size: 15px;
        line-height: 21px;
        width: 50%;
        padding-right: 40px;
      }

      @include media-xs {
        font-size: 17px;
        line-height: 28px;
        width: 100%;
        padding-right: 40px;
      }
    }

    .applyButton {
      @include media-sm {
        margin-right: auto;
      }
    }

    .discoverLink {
      display: block;
      margin-top: 56px;
      color: #ef7c4a;
      font-size: 15px;
      font-weight: 700;
      text-transform: uppercase;
      background: none;
      border: none;

      @include media-sm {
        margin-top: 33px;
        margin-right: auto;
      }
    }

    .dots {
      position: absolute;
      left: 50%;
      bottom: -10%;
      transform: translateX(-15%);
      z-index: 1;

      img {
        width: 100%;
      }

      @include media-lg {
        transform: translateX(-35%);
      }

      @include media-md {
        width: 280px;
        bottom: -7%;
        transform: translateX(-33%);
      }

      @include media-sm {
        width: 210px;
        bottom: -4%;
      }
    }

    .images {
      position: absolute;
      top: 0;
      right: 0;
      width: 50vw;
      height: 100%;

      @include media-md {
        width: 50%;
        height: 645px;
      }

      @include media-sm {
        height: 457px;
      }

      .top {
        width: 303px;
        position: absolute;
        top: -220px;
        left: 50%;
        transform: translateX(-81%);
        z-index: -1;
        border-radius: 10px;

        @include media-lg {
          width: 280px;
          top: -190px;
          transform: translateX(-77%);
        }

        @include media-md {
          width: 220px;
          top: -150px;
          transform: translateX(-75%);
        }

        @include media-sm {
          width: 150px;
          top: -110px;
        }
      }

      .mid {
        width: 303px;
        position: absolute;
        right: -85px;
        top: 152px;
        z-index: 1;
        border-radius: 10px;

        @include media-lg {
          width: 280px;
          right: -125px;
        }

        @include media-md {
          width: 220px;
          top: 110px;
        }

        @include media-sm {
          width: 150px;
          right: -45px;
          top: 75px;
        }
      }

      .bot {
        width: 392px;
        position: absolute;
        bottom: -80px;
        left: 50%;
        transform: translateX(calc(-81% - 15px));
        z-index: 2;
        border-radius: 10px;

        @include media-lg {
          width: 330px;
          bottom: 25px;
          transform: translateX(calc(-77% - 11px));
        }

        @include media-md {
          width: 260px;
          bottom: 40px;
          transform: translateX(calc(-75% - 6px));
        }

        @include media-sm {
          width: 185px;
          bottom: 45px;
        }
      }
    }
  }

  section {
    @include media-sm {
      flex-direction: column;
    }
    .imageArea {
      width: 488px;
      position: relative;
      margin-top: -38px;
      margin-right: 131px;
      z-index: 3;

      @include media-sm {
        margin: 0;
        width: 244px;
        align-self: flex-end;
      }

      .image {
        max-width: 100%;
        box-shadow: 0 23px 120px rgba($color: #000000, $alpha: 0.14);
        position: relative;
        position: relative;
        z-index: 2;
        border-radius: 10px;
      }

      .dots {
        position: absolute;
        left: -51px;
        bottom: -51px;
        z-index: -1;

        @include media-sm {
          left: -24px;
          bottom: -24px;
        }
      }
    }

    .contentArea {
      width: 495px;

      @include media-sm {
        width: 100%;
        margin-top: 70px;
      }

      .contentTitle {
        font-size: 40px;
        font-weight: 700;
        color: #000000;
        cursor: default;
      }

      .content {
        margin-top: 30px;
        cursor: default;

        p {
          margin-bottom: 32px;
          font-size: 16px;
          color: #000000;
          line-height: 1.6;

          &.quotes {
            position: relative;
            font-weight: 700;

            span {
              position: absolute;

              &.quoteStart {
                left: -12px;
                top: -8px;
                transform: translateX(-100%);

                @include media-sm {
                  left: 27px;
                  top: -12px;
                }
              }

              &.quoteEnd {
                right: 0;
                bottom: -25px;
                transform: translateX(75%);

                @include media-sm {
                  right: 7px;
                  bottom: -15px;
                }
              }
            }
          }
        }

        ul {
          padding-left: 0;
          list-style: none;

          li {
            position: relative;
            padding-left: 20px;
            font-size: 16px;
            margin-bottom: 24px;

            &:before {
              content: "";
              width: 16px;
              height: 12px;
              background: url("../utils/assets/bl-tick.png") no-repeat center;
              background-size: cover;
              border-radius: 50%;
              position: absolute;
              left: 0;
              top: 4px;
            }
          }
        }
      }
    }

    &.opposite {
      background: rgb(255, 251, 250);
      background: linear-gradient(
        90deg,
        rgba(255, 251, 250, 1) 0%,
        rgba(255, 246, 243, 1) 100%
      );
      flex-direction: row-reverse;

      @include media-sm {
        flex-direction: column;
      }

      .imageArea {
        margin-right: 0;
        margin-left: 61px;
        margin-top: -111px;

        @include media-sm {
          margin: -62px 0 0;
          align-self: flex-start;
        }

        .dots {
          left: auto;
          right: -51px;

          @include media-sm {
            right: -22px;
          }
        }
      }

      .contentArea {
        width: 569px;

        @include media-sm {
          width: 100%;
        }

        p {
          padding-right: 51px;
        }
      }
    }

    &.ourStory {
      padding-top: 300px;

      @include media-sm {
        padding: 65px 30px 112px;
      }
    }

    &.joinBLFamily {
      padding-top: 216px;
      margin-top: -51px;
      padding-bottom: calc(51px + 54px);

      @include media-sm {
        margin: 0;
        padding: 0 30px 98px;
      }
    }

    &.growYourCompany {
      padding-top: 106px;
      padding-bottom: 98px;

      @include media-sm {
        padding: 58px 30px 180px;
      }

      .imageArea {
        margin-top: 0;
      }

      .contentArea {
      }
    }

    &.howDoWeWork {
      padding: 105px 0;

      @include media-sm {
        padding: 0 30px 79px;
      }

      .imageArea {
        margin-top: 0;

        @include media-sm {
          margin-top: -82px;
        }
      }
    }

    &.perksOfWorkingWUs {
      padding: 105px 0;

      @include media-sm {
        padding: 63px 30px 44px;
      }

      .imageArea {
        margin-top: 0;
      }
    }
  }

  .joinNow {
    padding: 60px 0 50px;
    color: #ffffff;
    background: rgb(231, 91, 43);
    background: linear-gradient(
      145deg,
      rgba(231, 91, 43, 1) 0%,
      rgba(238, 121, 71, 1) 53%,
      rgba(254, 185, 132, 1) 100%
    );

    @include media-sm {
      padding: 40px 30px 41px;
    }

    h4 {
      font-size: 35.2px;
      font-weight: 600;
      letter-spacing: -0.6px;
      margin-bottom: 26px;
      cursor: default;
      text-align: center;

      span {
        @include media-sm {
          text-align: center;
          line-height: 44.9px;
          font-size: 25px;
        }

        @include media-md-min {
          text-align: center;
          line-height: 44.9px;
          font-size: 25px;
        }

        @include media-lg-min {
          line-height: 1;
          font-size: 35.2px;
        }
        .textLogo {
          margin: 0 12px;
          display: inline-block;

          @include media-sm {
            margin: 0 0 0 3px;
          }
        }
      }

      a {
        position: relative;
        font-weight: 800;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -25px;
          transform: translateY(-100%);
          width: 100%;
          height: 10px;
          background: url("./assets/underline.svg") no-repeat center 100%;
        }
      }
    }

    .joinBlContent {
      text-align: center;
      max-width: 900px;
      padding: 0 15px;
      @include media-sm {
        margin-top: 30px;
      }

      p {
        margin-bottom: 0;
        font-size: 19.8px;
        font-weight: 300;
        line-height: 28.6px;
        cursor: default;

        @include media-sm {
          display: inline;
        }
      }
    }
    .joinButton {
      margin-top: 2rem;
    }
  }

  .landingFooter {
    margin-bottom: -3rem;
    padding-top: 49px;
    background: #161d27;
    justify-content: center;
    align-items: flex-start;

    @include media-sm {
      margin: 0;
      padding: 37px 30px 34px;
      align-items: center;
      flex-direction: column;
    }
  }

  .footerNav {
    flex-basis: 40%;
    margin: 0 30px 0 80px;

    @include media-sm {
      margin: 44px 0 0;
      flex-basis: 100%;
    }

    .navSec {
      flex-basis: 33.3%;
      margin-bottom: 35px;

      @include media-sm {
        flex-basis: calc(50% - 5px);
        margin-right: 10px;

        &:nth-child(2) {
          margin-right: 0;
        }
      }

      h6 {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
        margin-bottom: 9px;
        cursor: default;
      }

      ul {
        padding: 0;
        list-style: none;
        margin: 0;

        li {
          font-size: 13px;
          font-weight: 400;
          line-height: 26px;
          color: #ffffff;
          opacity: 0.5;
        }
      }
    }
  }

  .appsArea {
    h6 {
      color: #ffffff;
      margin-bottom: 25px;
      cursor: default;
    }

    a {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
