@import "../utils/assets/mixins";

.productsFix {
  .page-area {
    @include media-sm {
      margin-top: 90px;
      padding: 10px 0;
    }
  }
}
.single-product {
  .categories {
    .category {
      font-size: 15px;
      font-weight: 300;
      text-transform: lowercase;
      margin-bottom: 0.5em;
      width: max-content !important;

      &::first-letter {
        text-transform: uppercase;
      }

      @include media-sm {
        font-size: 13px;
      }
    }
  }

  .product-code {
    font-weight: 600;

    @include media-sm {
      font-size: 13px;
    }

    span {
      font-weight: 300;
    }
  }

  .actions {
    .new-ticket-link {
      z-index: 2;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      color: #e75b2b;
    }
  }
}

.TicketsPage {
  .ticket-details {
    min-width: 400px;

    .status-badge {
      top: 20px;
      right: 20px;
    }

    .details {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 30px;

      dl {
        dd {
          margin: 0;
        }
      }
    }
  }
}

#product-detail {
  .content {
    padding: 0;

    @include media-sm {
      border-radius: 10px;
    }

    .image {
      width: 100%;
      height: 200px;
      overflow: hidden;

      img {
        max-width: 100%;
      }

      .status {
        bottom: 15px;
        right: 15px;
        background: rgba(#ffffff, 0.2);
        color: #fff;
        padding: 10px 13px;
        border-radius: 16.5px;

        @include media-sm {
          font-size: 11px;
        }
      }
    }

    .info {
      padding: 20px;

      .exp-title {
        margin-bottom: 15px;
        width: 100%;

        h6 {
          font-size: 13px;
          font-weight: bold;
          color: #515151;
          text-transform: uppercase;
        }

        span {
          font-size: 17px;
          font-weight: 600;
          margin-top: 12px;
        }
      }

      .exp-info {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        h6 {
          font-size: 15px;
          font-weight: 300;

          @include media-sm {
            font-size: 13px;
            margin: 0;
            line-height: 25px;
          }
        }

        span {
          font-size: 15px;
          font-weight: 600;

          @include media-sm {
            font-size: 13px;
            line-height: 25px;
          }
        }
      }

      .clone-this-link {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 13px;
      }
      .clone-desc {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}
