.profile-popup-enter {
  opacity: 0;
}

.profile-popup-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.profile-popup-exit {
  opacity: 1;
  transform: translateY(0);
}

.profile-popup-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.discounted-price-area-enter {
  opacity: 0;
}

.discounted-price-area-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.discounted-price-area-exit {
  opacity: 1;
}

.discounted-price-area-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.special-price-type-area-enter {
  opacity: 0;
  transform: translateY(-50%);
}

.special-price-type-area-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.special-price-type-area-exit {
  opacity: 1;
  transform: translateY(0);
}

.special-price-type-area-exit-active {
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 300ms, transform 300ms;
}

.date-preferences-enter {
  opacity: 0;
  transform: translateY(-50%);
}

.date-preferences-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.date-preferences-exit {
  opacity: 1;
  transform: translateY(0);
}

.date-preferences-exit-active {
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 300ms, transform 300ms;
}

.time-preferences-enter {
  opacity: 0;
  transform: translateY(-50%);
}

.time-preferences-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.time-preferences-exit {
  opacity: 1;
  transform: translateY(0);
}

.time-preferences-exit-active {
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 300ms, transform 300ms;
}

.modal-enter {
  opacity: 0;
}

.modal-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.modal-exit {
  opacity: 1;
}

.modal-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.modal-content-enter {
  opacity: 0;
  transform: scale(0.2);
}

.modal-content-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: transform 200ms, opacity 300ms;
}

.modal-content-exit {
  opacity: 1;
  transform: scale(1);
}

.modal-content-exit-active {
  opacity: 0;
  transform: scale(0.2);
  transition: transform 200ms, opacity 300ms;
}

.sidebar-content-enter {
  max-height: 0px;
  transition: max-height 400ms ease;
}

.sidebar-content-enter-active {
  max-height: 600px;
}

.sidebar-content-exit {
  max-height: 600px;
  transition: max-height 400ms ease;
}

.sidebar-content-exit-active {
  max-height: 0px;
}

.finance-rest-enter {
  opacity: 0;
  transition: opacity 400ms ease;
}

.finance-rest-enter-active {
  opacity: 1;
}

.finance-rest-exit {
  opacity: 1;
  transition: opacity 400ms ease;
}

.finance-rest-exit-active {
  opacity: 0;
}

.mobile-menu-enter {
  opacity: 0;
  transform: translateY(-10%);
}

.mobile-menu-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 350ms;
}

.mobile-menu-exit {
  opacity: 1;
  transform: translateY(0);
}

.mobile-menu-exit-active {
  opacity: 0;
  transform: translateY(-10%);
  transition: opacity 300ms, transform 350ms;
}
