.BucketlistCheckbox {
  width: 100;
}

.Checkbox {
  display: none;
}

.Label {
  display: flex;
  cursor: pointer;

  &:before {
    content: "";
    width: 22px;
    height: 22px;
    border: 1.2px solid #e6e6e6;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 9px;
    font-size: 20px;
  }

  span {
    max-width: calc(100% - 22px - 9px);
  }
}

.Checkbox:checked ~ .Label {
  &:before {
    content: "\2713";
    background: #fff;
    color: #e75b2b;
  }
}
