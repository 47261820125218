@import "../utils/assets/mixins";

.app {
  .approvalProgress {
    width: 100%;
    margin: 60px auto -55px;
    background: rgb(251, 206, 105);
    background: linear-gradient(
      126deg,
      rgba(251, 206, 105, 1) -50%,
      rgba(255, 198, 74, 1) -5%,
      rgba(240, 176, 36, 1) 50%
    );
    border-radius: 10px;
    position: relative;
    z-index: 3;
    padding: 20px 12px 24px;
    box-shadow: 0 0 16px 8px rgba($color: #000000, $alpha: 0.05);

    &[data-completed="true"] {
      background: rgb(128, 216, 140);
      background: linear-gradient(
        130deg,
        rgba(128, 216, 140, 1) -20%,
        rgba(109, 212, 152, 1) 30%,
        rgba(56, 202, 187, 1) 90%
      );
    }

    @include media-md {
      padding: 24px 15px;
    }

    @include media-sm-min {
      padding: 20px 15px 24px 40px;
    }

    .infoArea {
      width: calc(100% - 18px - 5px);

      .approvalText {
        font-size: 15px;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 15px;

        span {
          margin-left: 4px;
          font-weight: 400;
        }
      }

      .progressBar {
        width: 95%;
        height: 10px;
        background: rgba($color: #ffffff, $alpha: 0.3);
        border-radius: 5px;

        &:before {
          content: "";
          width: 0%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: #ffffff;
          border-radius: 5px;
          transition: width 200ms;
        }

        &:after {
          position: absolute;
          left: 0;
          bottom: 0;
          transform: translate(-50%, calc(100% + 3px));
          font-size: 12px;
          font-weight: 700;
          color: #ffffff;
        }

        @for $var from 0 to 101 {
          &[data-progress="#{$var}"] {
            &:before {
              width: $var#{"%"};
            }

            &:after {
              content: "#{$var}%";
              left: $var#{"%"};
            }
          }
        }
      }
    }

    a {
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  .page-area {
    &.appFix {
      margin-top: 44px;
    }

    .sub-title {
      font-size: 15px;
      font-weight: 500;
      text-transform: uppercase;
      color: #515151;
    }

    .experiences {
      .experience-holder {
        .experience {
          img {
            width: 50px;
          }

          .info {
            width: calc(100% - 50px - 20px - 1rem);

            .title {
              font-size: 20px;
              color: #e75b2b;
              font-weight: 600;
            }

            .view_count {
              font-weight: 600;

              span {
                font-weight: 300;
                color: #646464;
              }
            }
          }
        }

        &:last-child {
          .divider {
            display: none;
          }
        }
      }
    }
  }
}
