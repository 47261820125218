@import "../utils/assets/mixins";

.financeFix {
  @include media-sm {
    padding: 20px 13px 40px;
    margin-top: 44px !important;
  }
}

.commissionPageHolder {
  @include media-lg {
    margin-top: -20px !important;
  }
}

.Finance {
  .financeType {
    margin-bottom: 20px;
    .title {
      font-size: 22px;
      font-weight: 700;
      color: #000000;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      a {
        font-size: 15px;
        font-weight: 500;
        color: #e75b2b;
      }
    }
    .financeContentHolder {
      margin-top: 30px;
      .SingleFinance {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e7e7e7;
        padding-bottom: 22px;
        margin-bottom: 20px;
        height: 75px;

        @include media-sm {
          align-items: flex-start;
        }

        .downloadButton {
          width: 50px;
          height: 50px;
        }

        .info {
          .title {
            font-size: 20px;
            font-weight: 600;
            color: #e75b2b;
            line-height: 20px;
            margin-bottom: 10px;

            @include media-sm {
              font-size: 18px;
            }
          }
          .date {
            font-size: 15px;
            font-weight: 600;
            color: #000000;
            line-height: 17px;
            margin: 0;
            span {
              font-weight: 300;
              color: #656565;
            }
          }
        }

        .price {
          font-size: 20px;
          font-weight: 600;
          color: #000000;
          line-height: 20px;

          @include media-sm {
            font-size: 18px;
          }
        }
      }
    }
  }
}
