@import "../utils/assets/variables";
@import "../utils/assets/mixins";

.seePassBtn {
  bottom: 0;
  right: 0;
}

.applyLink {
  a {
    color: $primary;
    font-weight: 600;

    &:hover {
      color: $primary;
    }
  }
  @include media-sm() {
    font-size: 13px;
  }
}

.register-form {
  input {
    &#phone {
      &::placeholder {
        opacity: 0;
      }

      &:focus {
        &::placeholder {
          opacity: 1;
        }
      }
    }
  }

  button {
    .disabled-tooltip {
      top: 0;
      left: 50%;
      transform: translate(-50%, calc(-100% - 15px));
      background: rgba($color: #000000, $alpha: 0.8);
      border-radius: 8px;
      padding: 10px;
      box-shadow: 0 4px 8px 4px rgba($color: #000000, $alpha: 0.15);
      opacity: 0;
      z-index: -1;
      transition: opacity 300ms ease 0ms, z-index 0ms ease 310ms;
      font-size: 14px;
      font-weight: 400;
      color: #f1f1f1;

      &:after {
        content: "";
        width: 0;
        height: 0;
        border: 7px solid transparent;
        border-top-color: rgba($color: #000000, $alpha: 0.8);
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 100%);
      }
    }

    &:disabled {
      &:hover {
        .disabled-tooltip {
          opacity: 1;
          z-index: 5;
          transition: opacity 300ms ease 10ms, z-index 0ms ease 0ms;
        }
      }
    }
  }

  .agreements {
    font-size: 11px;
    font-weight: 400;

    a {
      font-weight: 600;
    }
  }
}

.bl-modal-content {
  &.apply-fix {
    overflow: hidden;
    max-height: 100%;

    .icon {
      width: 90px;
      height: 90px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
      color: #333;
    }

    .content {
      overflow-y: auto;
      font-size: 14px;
      margin-bottom: 20px;

      p {
        &:last-child {
          margin: 0;
        }
      }
    }

    .close-button {
      padding: 14px 0;
      font-size: 17px;
      font-weight: bold;
      align-self: center;
    }
  }
}
