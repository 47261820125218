@import "../assets/mixins";

.tabs {
  width: 100%;

  .tabNavHolder {
    width: 100%;
    overflow: auto;
    overflow-y: hidden;
    .TabsNav {
      list-style: none;
      border-bottom: 1px solid #e7e7e7;

      @include media-sm {
        border-bottom-color: transparent;

        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          background: #e7e7e7;
          bottom: 0;
          left: 0;
        }
      }

      .TabNavsItem {
        font-weight: 500;
        color: #000;
        font-size: 16px;
        white-space: nowrap;

        @include media-sm {
          font-size: 14.4px;
          letter-spacing: -0.36px;

          a {
            padding: 8px 15px;
          }
        }

        .resultsCount {
          height: 20px;
          min-width: 20px;
          border-radius: 12px;
          font-size: 12px;
          background: #ececec;
          margin-left: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: auto;
          padding: 6px;
        }

        .active {
          color: #e75b2b;
          position: relative;

          &:after {
            content: "";
            width: 100%;
            height: 2px;
            background: #e75b2b;
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translateY(0.5rem);

            @include media-sm {
              z-index: 2;
            }
          }

          .resultsCount {
            background: #ffe2d1;
          }
        }
      }
    }
  }
}
