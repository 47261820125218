@import "../assets/mixins";

.AutoSuggest {
  .input {
    padding: 24px 0 24px 68px;
    font-size: 18px;
    font-weight: 300;
    border-radius: 4px;
    border: none;

    @include media-sm {
      padding: 18px 0 18px 54px;
      font-size: 14px;
    }

    &::placeholder {
      color: rgba($color: #000000, $alpha: 0.3);
    }
  }

  .removeBtn {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    opacity: 0;
    z-index: -1;
    transition: opacity 200ms ease 0ms, z-index 0ms ease 210ms;

    &.active {
      transition: opacity 200ms ease 10ms, z-index 0ms ease 0ms;
      z-index: 1;
      opacity: 1;
    }
  }

  .icon {
    top: 50%;
    left: 20px;
    transform: translateY(-50%);

    @include media-sm {
      left: 22px;
      svg {
        width: 22px;
        height: 22px;
      }
    }

    .loadingDots {
      opacity: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      span {
        width: 10px;
        height: 10px;
        margin: 2px;
      }
    }

    &.searching {
      svg {
        opacity: 0;
      }

      .loadingDots {
        opacity: 1;
      }
    }
  }

  .suggestionsHolder {
    bottom: -5px;
    left: 0px;
    transform: translateY(100%);
    background: #fff;
    z-index: 3;
    border-radius: 4px;
    box-shadow: 0 22px 74px rgba(#000000, 0.15);
    margin-top: 5px;
    overflow: hidden;

    .suggestion {
      padding: 0 25px;
      background: #ffffff;
      font-size: 16px;
      font-weight: 300;
      line-height: 50px;

      &:hover {
        background: #e4e9ec;
      }

      .highlighted {
        background: rgba(#ef7d4b, 0.3);
        padding: 5px;
        padding-left: 0;
        padding-right: 0;
      }
    }
    .noResultFound {
      padding: 10px 16px;
      margin: 0;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.7);
    }
  }
}
