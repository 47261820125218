@import "./mixins";

* {
  outline: none;
  &::selection {
    background: transparent;
    color: inherit;
  }
}

html {
  scroll-behavior: smooth;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

body {
  overflow-x: hidden;
  max-width: 100vw;
  @include media-sm {
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }
}

.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
}

p {
  &::selection {
    color: $selectionColor;
    background: $selectionBG;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &::selection,
  *::selection {
    color: $selectionColor;
    background: $selectionBG;
  }
}

.selectable {
  &::selection,
  *::selection {
    color: $selectionColor;
    background: $selectionBG;
  }
}

.not-selectable {
  &::selection,
  *::selection {
    color: inherit;
    background: transparent;
  }
}

.page-title {
  font-size: 44px;
  font-weight: bold;
  text-align: center;
  color: #fff;

  @include media-sm() {
    font-size: 36px;
  }
}

.page-area {
  width: 100%;
  padding: 30px 40px;
  margin-top: 4rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 42px 84px rgba(0, 0, 0, 0.07);

  @include media-sm() {
    padding: 23px 20px 41px;
    margin-top: 74px;
  }
}

.form-group {
  input {
    font-size: 15px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    background: #fff;
    padding: 14px;
    height: auto;
    color: #1b1b1b;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    &.safari-fix {
      padding-right: 40px;
    }

    &::selection,
    *::selection {
      color: $selectionColor;
      background: $selectionBG;
    }

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }

    &.error {
      border-color: #ea3325;
    }
  }

  textarea {
    font-size: 15px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    background: #fff;
    padding: 14px;
    height: auto;
    resize: none;
    color: #1b1b1b;

    &.resizable {
      min-height: 120px;
      resize: vertical;
      max-height: 250px;
    }

    &::selection,
    &::-moz-selection {
      color: $selectionColor;
      background: $selectionBG;
    }

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  select {
    font-size: 15px;
    color: #1b1b1b;

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
      border-color: #e6e6e6;
    }

    border-radius: 4px;
    border: 1px solid #e6e6e6;
    padding: 14px;
    height: auto;
  }

  &.sliding-label {
    label {
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
      cursor: text;
      transition: all 200ms;
      padding: 0 10px;
      z-index: 3;
    }

    textarea ~ label {
      top: 14px;
      transform: none;
    }

    input:focus ~ label,
    input.filled ~ label,
    select.filled ~ label,
    textarea:focus ~ label,
    textarea.filled ~ label {
      font-size: 13px;
      top: 0;
      transform: translateY(-50%);
      cursor: default;
      background: #fff;
      border-radius: 4px;
    }
  }
}

small.form-text {
  font-size: 11px;
  font-style: italic;
  font-weight: 400;
  color: #7d7d7d;
}

strong {
  &::selection,
  *::selection {
    color: $selectionColor;
    background: $selectionBG;
  }
}

.button-primary {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  border-radius: 4px;
  padding: 14px 0;
  background: rgb(37, 198, 252);
  background: linear-gradient(
    175deg,
    rgba(37, 198, 252, 1) 0%,
    rgba(37, 198, 252, 1) 31%,
    rgba(75, 160, 255, 1) 100%
  );

  &:disabled {
    background: #a6a6a6;
    position: relative;
  }

  &.opposite {
    background: #fff;
    color: rgb(37, 198, 252);
    font-weight: 600;
    position: relative;
    $border: 1px;
    background: #fff;
    background-clip: padding-box;
    border: solid $border transparent;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -$border;
      border-radius: inherit;
      background: rgb(37, 198, 252);
      background: linear-gradient(
        175deg,
        rgba(37, 198, 252, 1) 0%,
        rgba(37, 198, 252, 1) 31%,
        rgba(75, 160, 255, 1) 100%
      );
    }
  }

  &.request-progress {
    background: #a6a6a6 !important;
    color: #a6a6a6 !important;
    position: relative;

    &:hover,
    &:active,
    &:visited,
    &:focus {
      color: #a6a6a6;
    }

    &:before {
      content: "";
      width: 0;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgb(231, 91, 43);
      background: linear-gradient(
        115deg,
        rgba(231, 91, 43, 1) 0%,
        rgba(254, 185, 132, 1) 100%
      );
      border-radius: 4px;
      z-index: 1;
    }

    @for $var from 0 to 101 {
      &[data-request-progress="#{$var}%"] {
        &:before {
          width: $var#{"%"};
        }
      }
    }

    &:after {
      content: attr(data-request-progress);
      width: 100%;
      height: 100%;
      background: transparent;
      color: #ffffff;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 4px;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &:hover,
  &:active,
  &:visited,
  &:focus {
    color: #ffffff;
    text-decoration: none;

    &.opposite {
      color: rgb(37, 198, 252);
    }
  }
}

.button-secondary {
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  border-radius: 4px;
  padding: 14px 0;
  background: rgb(231, 91, 43);
  background: linear-gradient(
    115deg,
    rgba(231, 91, 43, 1) 0%,
    rgba(254, 185, 132, 1) 100%
  );

  &.opposite {
    color: rgb(231, 91, 43);
    font-weight: 600;
    position: relative;
    background: transparent;
    background-clip: padding-box;
    border: solid 1px rgb(231, 91, 43);
  }

  &:disabled {
    background: #a6a6a6;
    border-color: #a6a6a6;
    color: #ffffff;
  }
}

.button-success {
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  border-radius: 4px;
  padding: 14px 0;
  background: rgb(128, 216, 140);
  background: linear-gradient(
    135deg,
    rgba(128, 216, 140, 1) 0%,
    rgba(109, 212, 152, 1) 20%,
    rgba(56, 202, 187, 1) 100%
  );

  &:disabled {
    background: #a6a6a6;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background: #e7e7e7;
  margin: 20px 0;

  &.list {
    width: calc(100% - 50px - 1rem);

    @include media-sm {
      width: calc(100% - 50px - 1rem - 12px);
    }
  }
}

.button-transparent {
  border: none;
  color: #000000;
  font-size: 17px;
  font-weight: normal;
  border-radius: 4px;
  padding: 14px 0;
  background: transparent;
  border: none;

  &:disabled {
    color: #a6a6a6;
  }
}

.pagination {
  .nav-button {
    height: 32px;
    min-width: 32px;
    border: 1px solid #d7d7d7;
    border-radius: 16px;
    margin: 0 5px;
    font-size: 16px;

    &.active {
      background: #e75b2b;
      border-color: #e75b2b;
      color: #fff;
    }
  }

  .pages {
  }
}

.experience-holder {
  .experience {
    @include media-sm {
      padding: 0 12px;
    }

    .cover-image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
    }

    .info {
      width: calc(100% - 50px - 20px - 1rem);

      .title {
        font-size: 20px;
        color: #e75b2b;
        font-weight: 600;

        @include media-sm {
          font-size: 18px;
        }
      }

      .view_count {
        font-weight: 600;

        span {
          font-weight: 300;
          color: #646464;
        }
      }
    }
  }

  &:last-child {
    .divider {
      display: none;
    }
  }
}

.bl-document-modals {
  overflow: hidden auto;

  .content {
    max-width: 630px !important;

    @include media-sm {
      max-width: 80vw !important;
      padding: 15px !important;
    }
  }
}

.bl-modal-content {
  &.scrollable {
    overflow: hidden auto;
  }
  .close-button {
    padding: 14px 0;
  }
}

.custom-file {
  .custom-file-label {
    height: auto;
    padding: 14px 24px;
    border-color: #e6e6e6;
    border-radius: 4px;
    // color: #1b1b1b;
    color: #e75b2b;

    &:after {
      content: attr(data-select);
      height: 100%;
      background: transparent;
      border: none;
      color: #e75b2b;
      padding: 14px 20px;
      display: none;
    }
  }
}

@include media-sm() {
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

button {
  outline: none;
  outline-offset: -2px;

  &:focus,
  &:active,
  &:hover {
    outline: none !important;
  }

  &::-moz-focus-inner {
    border: 0;
  }
}

#react-confirm-alert {
  position: relative;
  z-index: 999;

  .react-confirm-alert-overlay {
    background: rgba($color: #000000, $alpha: 0.5);

    .react-confirm-alert {
      .react-confirm-alert-body {
        .react-confirm-alert-button-group {
          button {
            &:first-child {
              background: transparent;
              color: #1b1b11;
            }

            &:last-child {
              background: #e75b2b;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

.loading-dots {
  span {
    width: 16px;
    height: 16px;
    margin: 4px;
    border-radius: 50%;
    animation-name: loadingDots;
    animation-duration: 800ms;
    animation-iteration-count: infinite;

    &:nth-child(2) {
      animation-delay: 200ms;
    }

    &:nth-child(3) {
      animation-delay: 400ms;
    }
  }
}

.routes-loader {
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 9999;

  svg {
    animation: pulseLogo 750ms linear 0ms infinite;
  }
}

#documentDetail {
  .content {
    max-width: 90vw;
    max-height: 90vh;

    @include media-md-min {
      max-width: 70vw;
      max-height: 80vh;
    }

    .close-button {
      max-width: 320px;
    }
  }
}

.request-progress {
  background: #a6a6a6 !important;
  color: #a6a6a6 !important;
  position: relative;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    color: #a6a6a6;
  }

  &:before {
    content: "";
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(231, 91, 43);
    background: linear-gradient(
      115deg,
      rgba(231, 91, 43, 1) 0%,
      rgba(254, 185, 132, 1) 100%
    );
    border-radius: 4px;
    z-index: 1;
  }

  @for $var from 0 to 101 {
    &[data-request-progress="#{$var}%"] {
      &:before {
        width: $var#{"%"};
      }
    }
  }

  &:after {
    content: attr(data-request-progress);
    width: 100%;
    height: 100%;
    background: transparent;
    color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
