.nav-menu-mobile {
  width: 100%;
  background: rgba(#ffffff, 0.15);
  border-radius: 20px;
  padding: 13px 18px 13px 15px;
  border: none;
  .label {
    color: #fff;
    font-size: 13px;
    font-weight: 500;

    .help-detail-title {
      margin-right: 4px;
    }
    .cat-title {
      font-weight: 700;
    }
  }

  .currPage {
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    svg {
      margin-left: 11px;
      transform: rotate(0);
      transition: transform 200ms;

      &.menu-active {
        transform: rotate(-180deg);
      }
    }
  }
}

.nav-menu-mobile-content {
  width: 100%;
  position: absolute;
  z-index: 24;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 21px 109px rgba(0, 0, 0, 0.14);
  list-style: none;
  top: calc(42.5px + 6px);
  padding: 20px 12px 6px 15px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -15px;
    background: transparent;
    width: 100vw;
    height: calc(100vh - 72px - 44px - 23px - 9px);
    z-index: 2;
  }

  .nav-menu-cat-title {
    label {
      font-size: 15px;
      font-weight: 700;
      line-height: 50px;
      cursor: pointer;
      position: relative;
      z-index: 4;

      &:after {
        content: "";
        width: calc(100% - 35px);
        height: 1px;
        background: #eeeeee;
        position: absolute;
        bottom: -5px;
        right: 0;
      }

      img {
        margin-right: 12px;
      }
      svg {
        transition: transform 300ms;
      }
    }

    .nav-menu-cat-content {
      z-index: -3;
      max-height: 0px;
      overflow: hidden;
      transition: z-index 0ms ease 410ms, max-height 400ms ease 0ms;
      list-style: none;
      padding: 0;

      .nav-menu-item {
        position: relative;
        padding-left: 15px;

        &:last-child {
          border: none;
        }
        .nav-menu-link {
          font-size: 14px;
          font-weight: 500;
          padding: 16px 30px 16px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          z-index: 2;
          border-bottom: 1px solid #eeeeee;

          &:hover,
          &:active,
          &:focus {
            text-decoration: none;
          }

          &.active {
            font-weight: 700;
            color: #f18351;
          }
        }
      }
    }

    input {
      display: none;

      &:checked {
        & ~ label {
          svg {
            transform: rotate(-180deg);
          }
        }

        & ~ ul {
          z-index: 1;
          max-height: 999px;
          transition: z-index 0ms ease 0ms, max-height 400ms ease 30ms;
        }
      }
    }
  }
}
