@import "../utils/assets/mixins";

.reviewsFix {
  @include media-sm {
    margin-top: 44px !important;
    padding: 23px 13px 40px !important;
  }
}

.Reviews {
  .Review {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 14px;

    .avatar {
      width: 50px;
      height: 50px;
      overflow: hidden;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      img {
        width: 100%;
        border-radius: 50%;
      }
    }

    .info {
      width: calc(100% - 50px - 10px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .topInfo {
          .userName {
            font-size: 20px;
            font-weight: 600;
            color: #e75b2b;
            margin-bottom: 8px;
            line-height: 20px;

            @include media-sm {
              font-size: 17px;
            }
          }
          .product {
            font-size: 15px;
            font-weight: 700;
            color: #333333;
          }
        }
      }

      .bottom {
        width: 100%;
        .reviewTitle {
          font-size: 15px;
          font-weight: 500;
          line-height: 21px;
          color: #000000;
        }

        .reviewContent {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          color: #000000;
          padding-right: 110px;

          @include media-sm {
            padding: 0;
          }
        }

        .reviewDivider {
          @include media-sm {
            width: calc(100% + 14px);
          }
        }
      }
    }
  }
}
