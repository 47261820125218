@import "../utils/assets/mixins";

.helpCenter {
  padding: 0 60px;

  @include media-sm {
    padding: 0;
  }

  &:before {
    content: "";
    position: fixed;
    top: -163px;
    left: 0;
    width: 100%;
    height: calc(100% + 163px);
    background: rgb(231, 91, 43);
    background: linear-gradient(
      115deg,
      rgba(231, 91, 43, 1) 0%,
      rgba(254, 185, 132, 1) 100%
    );
    z-index: -1;
  }

  .pageTitle {
    font-size: 43px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 42px;

    @include media-sm {
      font-size: 31px;
      line-height: 40px;
      margin-top: 18px;
      margin-bottom: 26px;
    }
  }

  $transitionDuration: 400ms;

  .helpCenterExamples {
    .helpBox {
      height: 240px;
      background: rgba($color: #ffffff, $alpha: 0.1);
      border-radius: 10px;
      transition: background $transitionDuration;
      border-radius: 10px;
      padding: 25px 20px 20px;
      flex-basis: calc(33.33% - 17px);
      position: relative;
      overflow: hidden;
      margin-right: 25px;
      margin-bottom: 25px;

      &:nth-child(3n + 3) {
        margin-right: 0;
      }

      @include media-sm {
        height: 160px;
        flex-basis: calc(50% - 4.8px);
        margin-right: 9.5px;
        margin-bottom: 10px;
        padding: 23px 5px;

        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      }

      &.comingSoon {
        &:after {
          content: "Çok Yakında";
          width: 113px;
          height: 110px;
          font-size: 12px;
          font-weight: 700;
          color: #ffffff;
          text-transform: uppercase;
          position: absolute;
          padding: 0 12px 5px;
          line-height: 14px;
          text-align: center;
          top: -54px;
          right: -55px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          transform: rotate(45deg);
          background: rgba($color: #ff2929, $alpha: 0.4);
          z-index: 4;
          transition: background $transitionDuration;

          @include media-sm {
            font-size: 9.6px;
            width: 90px;
            height: 100px;
            top: -56px;
            right: -52px;
            padding-bottom: 0;
          }
        }
      }

      .icon {
        width: 64px;
        height: 64px;
        background: #ffffff;
        padding: 15px;
        border-radius: 50%;
        transition: all $transitionDuration;

        svg {
          width: 100%;
          height: 100%;

          path {
            fill: #ef7d4b;
            transition: all $transitionDuration;
          }
        }
      }
      .contentHolder {
        height: calc(100% - 64px - 18px);
        margin-top: 18px;

        @include media-md {
          margin-top: 0;
        }

        @include media-lg {
          margin-top: 0;
        }

        .title {
          font-size: 22px;
          font-weight: 700;
          color: #ffffff;
          line-height: 30px;

          @include media-sm {
            font-size: 16px;
            line-height: 25.1px;
            text-align: center;
          }

          @include media-md {
            line-height: 24px;
          }

          @include media-lg {
            line-height: 24px;
          }
        }

        .desc {
          font-size: 15px;
          font-weight: 300;
          color: #ffffff;
          line-height: 24px;
        }
      }

      &:hover {
        background: #ffffff;
        box-shadow: 0 22px 74px rgba($color: #000000, $alpha: 0.14);

        &.comingSoon {
          &:after {
            background: #ff2929;
          }
        }

        .icon {
          background: #ef7d4b;

          svg {
            path {
              fill: #ffffff;
            }
          }
        }

        .title {
          color: #ef7d4b;
        }

        .desc {
          color: #000000;
        }

        @include media-sm {
          background: rgba($color: #ffffff, $alpha: 0.1);
          box-shadow: none;
          .icon {
            background: #ffffff;

            svg {
              path {
                fill: #ef7d4b;
              }
            }
          }

          .title {
            color: #fff;
          }

          .desc {
            color: inherit;
          }
        }
      }
    }
  }

  .buttonsArea {
    margin-top: 56px;

    p {
      color: #ffffff;
      font-size: 15px;
      font-weight: 300;
      line-height: 24px;
      margin-bottom: 23px;

      strong {
        font-weight: 700;
      }
    }

    .whiteButton {
      width: 200px;
      padding: 15px 0;
      border-radius: 4px;
      border: 1px solid #ffffff;
      background: #ffffff;
      color: #ef7d4b;
      font-size: 16px;
      font-weight: 600;

      &.opposite {
        background: transparent;
        color: #ffffff;
        margin-left: 10px;
      }
    }
  }
}

.HelpDetail {
  padding-top: 30px;

  @include media-sm {
    padding-top: 50px;
  }

  .contentSidebar {
    width: 100%;

    .sidebarTitle {
      font-size: 28px;
      font-weight: 700;
      color: #000000;
    }

    .sidebarLists {
      .sidebarList {
        label {
          width: 100%;

          .listTitle {
            font-size: 15px;
            font-weight: 600;
            color: #000000;
            line-height: 50px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-bottom: 1px solid #d8d8d8;
            position: relative;
            cursor: pointer;

            .icon {
              width: 28px;
              margin-left: -40px;
              margin-right: 12px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .arrow {
              margin-left: auto;
              transition: transform 250ms;
            }
          }
        }

        input {
          display: none;

          &:checked ~ label {
            .arrow {
              transform: rotate(-180deg);
            }
          }
        }

        .sidebarHolder {
          width: 100%;
          overflow: hidden;

          .sidebar {
            .content {
              border-bottom: 1px solid #d8d8d8;
              padding-bottom: 12px;
              margin-bottom: 10px;

              a {
                font-size: 15px;
                font-weight: 400;
                color: #000000;
                line-height: 19px;
                padding-right: 28px;

                &.active {
                  color: #ef7d4b;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
  }

  .Detail {
    @include media-lg-min {
      padding-left: 20px;
    }

    @include media-sm {
      margin-top: 30px;
    }

    .detailTitle {
      font-size: 36px;
      font-weight: 700;
      color: #000000;
      line-break: 44px;
      margin-bottom: 28px;

      @include media-sm {
        font-size: 21px;
        line-break: 26.4px;
      }
    }

    .detailContent {
      & > * {
        line-height: 25px;
        font-size: 15px;
      }

      a {
        color: #e75b2b !important;
        font-weight: 600;
      }
    }
  }
}
