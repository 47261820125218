@mixin media-xs() {
  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575px) {
    @content;
  }
}

@mixin media-xs-min() {
  // Extra small devices (portrait phones, less than 576px)
  @media (min-width: 575px) {
    @content;
  }
}

@mixin media-sm() {
  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767px) {
    @content;
  }
}

@mixin media-sm-min() {
  // Small devices (landscape phones, less than 768px)
  @media (min-width: 767px) {
    @content;
  }
}

@mixin media-md() {
  // Medium devices (tablets, less than 992px)
  @media (max-width: 991px) {
    @content;
  }
}

@mixin media-md-min() {
  // Medium devices (tablets, less than 992px)
  @media (min-width: 992px) {
    @content;
  }
}

@mixin media-lg() {
  // Large devices (desktops, less than 1200px)
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin media-lg-min() {
  // Large devices (desktops, less than 1200px)
  @media (min-width: 1199px) {
    @content;
  }
}
