@import "../utils/assets/mixins";
@import "../utils//assets/variables";
@import "../utils//assets//typography";

.notificationsFix {
  @include media-sm {
    margin-top: 20px;
  }

  .clearBtn {
    font-size: 13px;
    font-weight: $font-weight-bold;
    color: $primary;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;

    &.clearing {
      color: #d3d3d3;
      cursor: wait;
    }
  }
}

.Notifications {
  .SingleNotificationHolder {
    .SingleNotification {
      .ImageArea {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          border-radius: 50%;
        }
      }

      .InfoArea {
        .title {
          font-size: 14px;
          font-weight: $font-weight-bold;
          margin-bottom: 10px;
        }

        .content {
          font-size: 16px;
          color: #0c0c0c;
          margin-bottom: 12px;
        }

        .date {
          font-size: 13px;
          font-weight: 300;
          color: #656565;
        }
      }
    }

    .divider {
      width: calc(100% - 60px - 1.5rem);
    }

    &:last-child {
      .divider {
        display: none;
      }
    }
  }
}
